import { useEffect, useState } from 'react'
import { merchantApiType } from '../../request/merchant/merchantApi.interface'
import { merchant } from '../../request/merchant/merchant'
import { merchantApiDetail } from '../../request/merchant/merchantApiDetail.interface'
import { error } from '../../../../../../@WUM/core/Elements/index'
import { DatenblattDetailApiType } from '../../../../products/API/request/Product/DatenblattApi.interface'
import { checkLogin } from '../../../../../../@WUM/templates/default/Auth/service/AuthService'

export const Response = (page: string, searchValue: string) => {
    const [posts, setPosts] = useState<merchantApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await merchant
                .getPosts(page, searchValue)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [page, searchValue])

    return isError ? posts : posts
}

export const ResponseDetail = (
    id: string | undefined,
    setloading: any,
    setstateValue: (value: ((prevState: string) => string) | string) => void,
    setenabledState: (value: ((prevState: number) => number) | number) => void,
    setapproveState: (value: ((prevState: string) => string) | string) => void
) => {
    const [posts, setPosts] = useState<merchantApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await merchant
                .getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    localStorage.setItem('merchant', JSON.stringify(data))
                    setstateValue(
                        JSON.parse(localStorage.getItem('merchant') as string)
                            .merchant.state
                    )
                    setenabledState(
                        JSON.parse(localStorage.getItem('merchant') as string)
                            .merchant.enabled
                    )
                    setapproveState(
                        JSON.parse(localStorage.getItem('merchant') as string)
                            .merchant.approval_state
                    )
                    setloading(true)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id, setloading, setstateValue, setenabledState, setapproveState])

    return isError ? isError : posts
}

export const ResponseDataSheetsMerchants = (id: any) => {
    const [posts, setPosts] = useState<DatenblattDetailApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await merchant
                .getDatenblatt(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)

                    let rigonalBrand: any = []

                    for (let e = 0; e < data[0].children.length; e++) {
                        rigonalBrand.push({
                            formType: 'checkbox',
                            label: data[0].children[e].title,
                            name:
                                'rigonalBrand[' +
                                data[0].children[e].id +
                                '][title]',
                            id:
                                'rigonalBrand[' +
                                data[0].children[e].id +
                                '][title]',
                            visible: data[0].children[e].check
                        })
                    }

                    let form = { rigonalBrand: rigonalBrand }

                    localStorage.setItem(
                        'datasheetMerchant',
                        JSON.stringify(form)
                    )
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}
