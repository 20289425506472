import {useState} from "react";
import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {UniModalHeader} from "./Elements/UniModalHeader";
import {UniModalBody} from "./Elements/UniModalBody";
import {UniModalFooter} from "./Elements/UniModalFooter";
import React from 'react'
interface UniModalProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
    Button:{
        Text: string
        OnClick?: any;
        Variant?:string
        Class?:string
        ID?:string
        Type?:"button" | "submit" | "reset" | undefined
    }

}

const UniModal = (props:UniModalProps) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const ButtonFunc = () => {
        handleShow();
        props.Button.OnClick();
    }

    return(
        <>
            <Button type={props.Button.Type} variant={props.Button.Variant !== undefined ? props.Button.Variant : "primary"} id={props.Button.ID} className={props.Button.Class} onClick={ButtonFunc}>
                {props.Button.Text}
            </Button>
            <Modal show={show} onHide={handleClose}>
                {props.children}
            </Modal>
        </>
    )

}

UniModal.Header  = UniModalHeader
UniModal.Body = UniModalBody
UniModal.Footer = UniModalFooter

export default UniModal;
