import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import seo from '../../../../../@WUM/core/component/core/elements/seo'
import share from '../../../../../@WUM/core/component/core/elements/share'
import { blogApiDetail } from '../../API/request/blog/blogApiDetail.interface'
import { init2 } from '../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { configBlog, outputBlog } from '../configuration/mapping'
import { blog } from '../../API/request/blog/blog'
import { notify_save } from '../../../../../@WUM/core/component/const/notification.service'
import { error, IntlMessage } from '../../../../../@WUM/core/Elements/index'
import { Form } from 'react-bootstrap'
import { handleSubmitForm } from '../../../../../@WUM/core/Function/index'
import { useLocalStorage } from '../../../../../@WUM/core/component/const/useLocalStoreage'
import { useSelector } from 'react-redux'
import homeCategory from '../../../../../@WUM/core/component/core/elements/homeCategory'

/**
 * Give View NewBlog for Content Blog
 * @memberOf blog
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const BlogNewCategory = (props: { value: any }) => {
    //const user = 1;
    const history = useNavigate()

    const Store = useSelector((state: any) => state)

    const [currentTutorial, setCurrentTutorial] = useState<blogApiDetail[]>([])

    const [validated, setValidated] = useState(false)
    const [seo_title, setseo_title] = useLocalStorage('seo_title', '')
    const [seo_description, setseo_description] = useLocalStorage(
        'seo_description',
        ''
    )
    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     */
    const updateData = (status: boolean, id: any, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        let reauestData: blogApiDetail[] & configBlog = Object.assign(
            {},
            currentTutorial,
            outputBlog(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        blog.updatePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save(Store)
                setTimeout(() => (window.location.href = '/Blog/1'), 3000)
            })
            .catch((e) => {
                error(e)
            })
    }

    return (
        <div>
            <Form
                id={'form001'}
                noValidate
                validated={validated}
                onSubmit={(e) =>
                    handleSubmitForm(e, updateData, setValidated, 0)
                }
            >
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={
                            <IntlMessage
                                Store={Store}
                                messageId="common.general"
                            />
                        }
                        key={'allgemein'}
                        id={'allgemein'}
                        style={{
                            backgroundColor: 'White',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {homeCategory({ response: 'new', Store: Store })}
                    </Tab>
                    <Tab
                        eventKey="seo"
                        title={
                            <IntlMessage Store={Store} messageId="common.seo" />
                        }
                        key={'SEO'}
                        id={'SEO'}
                        style={{
                            backgroundColor: 'White',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {seo({
                            response: 'new',
                            seo_title: seo_title,
                            setseo_title: setseo_title,
                            seo_description: seo_description,
                            setseo_description: setseo_description,
                            Store: Store
                        })}
                    </Tab>
                    <Tab
                        eventKey="share"
                        title={
                            <IntlMessage
                                Store={Store}
                                messageId="common.share"
                            />
                        }
                        key={'Teilen'}
                        id={'Teilen'}
                        style={{
                            backgroundColor: 'White',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {share({ response: 'new', Store: Store })}
                    </Tab>
                </Tabs>
                <div style={{ backgroundColor: 'white', padding: '15px' }}>
                    <Button
                        variant="light"
                        style={{ float: 'left', marginLeft: '15px' }}
                        onClick={() => history(-1)}
                    >
                        <IntlMessage Store={Store} messageId="common.back" />
                    </Button>
                    <Button
                        variant="success"
                        style={{ float: 'right', marginRight: '15px' }}
                        type={'submit'}
                    >
                        {<IntlMessage Store={Store} messageId="common.save" />}
                    </Button>
                    <br style={{ clear: 'both' }} />
                </div>
            </Form>
        </div>
    )
}

export default BlogNewCategory
