import React, { useRef, useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import FormMaker from '../../../../../@WUM/core/form-serilizer'
import configuration from '../configuration/Page/configuration'
import { saveDataIndividualPut } from '../../../../../@WUM/core/component/const/saveData'
import { notify_save } from '../../../../../@WUM/core/component/const/notification.service'
import { error, IntlMessage } from '../../../../../@WUM/core/Elements/index'
import { handleSubmitForm } from '../../../../../@WUM/core/Function/index'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const TextBlocksNew = (props: { value: any }) => {
    const history = useNavigate()
    const [validated, setValidated] = useState(false)
    const Store = useSelector((state: any) => state)

    const forms = useRef(null)
    const submit = () => {
        let Dataform = forms.current
        if (Dataform !== undefined && Dataform !== null) {
            const data = new FormData(Dataform)
            let object: any = {}
            data.forEach((value, key) => {
                if (!Reflect.has(object, key)) {
                    object[key] = value
                    return
                }
                if (!Array.isArray(object[key])) {
                    object[key] = [object[key]]
                }
                object[key].push(value)
            })
            let json = JSON.stringify(object)

            saveDataIndividualPut('Content/TextBlock/insert/0/', json)
                .then((data: any) => {
                    notify_save(Store)
                    setTimeout(
                        () => (window.location.href = '/TextBlocks/overview/1'),
                        3000
                    )
                })
                .catch((e) => {
                    error(e)
                })
        }
    }

    return (
        <div>
            <Form
                autoComplete="none"
                ref={forms}
                id={'form001'}
                noValidate
                validated={validated}
                onSubmit={(e) => handleSubmitForm(e, submit, setValidated, 0)}
            >
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={
                            <IntlMessage
                                Store={Store}
                                messageId="common.general"
                            />
                        }
                        key={'Allgemein'}
                        id={'Allgemein'}
                        style={{
                            backgroundColor: 'White',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {FormMaker(configuration('dataColOne', [], true))}
                    </Tab>
                </Tabs>
                <div style={{ backgroundColor: 'white', padding: '15px' }}>
                    <Button
                        variant="light"
                        style={{ float: 'left', marginLeft: '15px' }}
                        onClick={() => history(-1)}
                    >
                        <IntlMessage Store={Store} messageId="common.back" />
                    </Button>
                    <Button
                        type={'submit'}
                        variant="success"
                        style={{ float: 'right', marginRight: '15px' }}
                    >
                        <IntlMessage Store={Store} messageId="common.save" />
                    </Button>
                    <br style={{ clear: 'both' }} />
                </div>
            </Form>
        </div>
    )
}

export default TextBlocksNew
