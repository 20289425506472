import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { init2 } from '../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from './configuration/mapping'
import { notify_save_intvall_reload } from '../../../../../@WUM/core/component/const/notification.service'
import { error, IntlMessage } from '../../../../../@WUM/core/Elements/index'
import { ResponseformularDetail } from '../../API/response/formular/formular'
import { formularApiDetail } from '../../API/request/formular/formularApiDetail.interface'
import { formular } from '../../API/request/formular/formular'
import { Form } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { handleSubmitForm } from '../../../../../@WUM/core/Function/index'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Formular from '../../../../../@WUM/core/form-serilizer/formElements'
import { Accordion } from 'react-bootstrap'
import ElementsContentAppendInterface from '../../../../../@WUM/core/component/core/interface/ElementsContentAppend.interface'
import { isEmptyArray } from 'formik'
import {
    newForms,
    Form as Forms
} from '../../../../../@WUM/core/form-serilizer/formElements/newForms'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Sortable from 'sortablejs'
import { position } from '../../../../../@WUM/core/component/core/elements/content'

/**
 * Give View BlogDetail for Content Blog
 * @memberOf blog
 * @prop id
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const FormularDetail = (props: { value: any }) => {
    const { id } = useParams<{ id: string }>()
    const history = useNavigate()
    const Store = useSelector((state: any) => state)
    const response2 = ResponseformularDetail(id)
    const [Index, setIndex] = React.useState(0)
    const [currentTutorial, setCurrentTutorial] =
        useState<formularApiDetail[]>(response2)
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    const [validated, setValidated] = useState(false)

    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     */
    const updateData = (status: boolean, id: any, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        //console.log(Data.form)

        let reauestData: formularApiDetail[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        //onsole.log(reauestData)

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        formular
            .updatePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save_intvall_reload(Store)
                $('#btn_save')[0].setAttribute('disabled', 'disbaled')
                setTimeout(() => window.location.reload(), 3000)
            })
            .catch((e) => {
                error(e)
            })
    }

    const append = (props: ElementsContentAppendInterface) => {
        let NewIndex

        if (isEmptyArray(props.element)) {
            if (props.response !== undefined) {
                NewIndex = props.response.length
            } else {
                NewIndex = 0
            }

            props.setIndex(NewIndex)
        } else {
            NewIndex = props.Index + 1
            props.setIndex(NewIndex)
        }
        // ok bin raus melde dich gleihc
        let para = newForms(
            props.discr,
            NewIndex,
            props.setdeleteElement,
            props.element,
            props.Store,
            props.galerie,
            props.galerieItems
        )
        console.log(para)
        let allElements = []
        for (let x = 0; x < props.element.length; x++) {
            allElements.push(props.element[x])
        }
        allElements.push(para)
        props.setElement(allElements)
    }
    let optionTextMap = []
    for (let xs = 0; xs < Forms(Store).length; xs++) {
        let text: string | JSX.Element = ''
        if (Forms(Store)[xs].value !== 'false') {
            text = Forms(Store)[xs].label
        }
        optionTextMap.push(
            <>
                <option value={Forms(Store)[xs].value}>{text}</option>
            </>
        )
    }
    const [deleteElement, setdeleteElement] = useState(0)
    const [element, setElement] = useState([])
    console.log(deleteElement)
    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value
        if (value !== 'false') {
            append({
                response: response2,
                setElement: setElement,
                discr: value,
                element: element,
                Index: Index,
                setIndex: setIndex,
                setdeleteElement: setdeleteElement,
                Store: Store,
                galerie: '',
                galerieItems: ''
            })

            let setselect = document.getElementById('newElement')
            if (setselect != null) {
                setselect.getElementsByTagName('option')[0].selected =
                    Boolean(true)
            }
        }
    }

    useEffect(() => {
        let nestedSortables = document.getElementsByClassName('nested-sortable')
        for (var i = 0; i < nestedSortables.length; i++) {
            new Sortable(nestedSortables[i] as HTMLElement, {
                group: 'nested',
                animation: 150,
                fallbackOnBody: true,
                swapThreshold: 0.65,
                onEnd: (evt) => {
                    position()
                }
            })
        }
    })

    if (response2 !== undefined) {
        return (
            <div style={{ backgroundColor: 'white', padding: '15px' }}>
                <Form
                    id={'form001'}
                    className="h-100"
                    noValidate
                    validated={validated}
                    onSubmit={(e) =>
                        handleSubmitForm(e, updateData, setValidated, id)
                    }
                >
                    <div>
                        <Accordion
                            id={'elements'}
                            className={'nested-sortable'}
                        >
                            {buildForm(
                                response2,
                                modalShow,
                                setModalShow,
                                modalData,
                                setModalData
                            )}
                            {element}
                        </Accordion>
                    </div>
                    <Row>
                        <Col md>
                            <FloatingLabel
                                controlId="floatingSelectGrid"
                                label={
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.new_element"
                                    />
                                }
                            >
                                <Form.Select
                                    id={'newElement'}
                                    aria-label="Floating label select example"
                                    onChange={selectChange}
                                >
                                    {optionTextMap}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <div className="white-pad-15" style={{ marginTop: '10px' }}>
                        <Button
                            variant="light"
                            className="marl-15"
                            onClick={() => history(-1)}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.back"
                            />
                        </Button>
                        <Button
                            variant="success"
                            id={'btn_save'}
                            className="marr-15"
                            type={'submit'}
                            style={{ float: 'right' }}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.save"
                            />
                        </Button>
                        <br style={{ clear: 'both' }} />
                    </div>
                </Form>
            </div>
        )
    } else {
        return (
            <>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                        <IntlMessage Store={Store} messageId="common.loading" />
                    </span>
                </Spinner>
            </>
        )
    }
}

export default FormularDetail

const buildForm = (
    response: any,
    modalShow: any,
    setModalShow: any,
    modalData: any,
    setModalData: any
) => {
    return Formular(
        response,
        modalShow,
        setModalShow,
        modalData,
        setModalData,
        false,
        '',
        '',
        '',
        '',
        '',
        ''
    )
}
