import React from 'react'
import PielersForm from '../../index'
import { Tabs } from 'react-bootstrap'
import { base, basesettings } from './base'
import Tab from 'react-bootstrap/Tab'
import { CopyElements, IntlMessage, IntlMessageValue, SunEditorBig, WebBuilderElementsHead } from '../../../Elements/index'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Box from '@mui/material/Box'

function module_text_imageWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any,
    galerie?: any,
    galerieItems?: any
) {
    const index = newElement === false ? i : newelementIndex

    const configuration = [
        {
            formType: 'multiply',
            cols: [
                SunEditorBig(
                    newElement === false ? element.translation.de : '',
                    'text',
                    newElement,
                    data,
                    'elements[' + index + '][translation][text]',
                    <IntlMessage Store={Store} messageId="module.text" />
                ),
                {
                    formType: 'image',
                    altLink: true,
                    id: 'image_text_image_' + index,
                    path:
                        newElement === false
                            ? element.translation.de.image !== undefined &&
                              element.translation.de.image !== null
                                ? element.translation.de.image?.cdn_url.replace(
                                      '/templates',
                                      'http://localhost/templates'
                                  )
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 2,
                        md: 2,
                        sm: 2
                    }
                }
            ]
        },
        {
            formType: 'formFile',
            onclick: true,
            id: 'image_titleFormField' + index,
            onclick_context:
                newElement === false
                    ? element.context
                    : 'pielers_element_text_image',
            Gallery: galerie,
            galerieItems: galerieItems,
            expandfile: index + 'editorial_first_image_id',
            experiment: true,
            append: 'elements[' + index + '][translation][image_id]',
            src: 'image_text_image_' + index,
            size: {
                xl: 10,
                md: 10,
                sm: 10
            }
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][translation][image_id]',
            id: 'elements[' + index + '][translation][image_id]',
            visible: true,
            label: 'imageID',
            type: 'text',
            placeholder: 'imageID',
            value: newElement === false ? element.translation.de.image_id : ''
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/media_image.html'
        }
    ]
    let newConfiguration = configuration.concat(
        base(element, i, newElement, newelementIndex)
    )

    const configSettings = [
        {
            formType: 'header',
            type: 'h5',
            value: IntlMessageValue(
                'de',
                'common.header.wrapper.settings',
                Store
            )
        },
        {
            formType: 'input',
            label: IntlMessageValue(
                'de',
                'common.m22.wrapper.class.label',
                Store
            ),
            type: 'text',
            placeholder: IntlMessageValue(
                'de',
                'common.m22.wrapper.class.placeholder',
                Store
            ),
            name: 'elements[' + index + '][translation][wrapper]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.wrapper
                        : ''
                    : ''
        },

        {
            formType: 'input',
            label: IntlMessageValue(
                'de',
                'common.m22.wrapper.item.class.label',
                Store
            ),
            type: 'text',
            placeholder: IntlMessageValue(
                'de',
                'common.m22.wrapper.item.class.placeholder',
                Store
            ),
            name: 'elements[' + index + '][translation][wrapper_item_class]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.wrapper_item_class
                        : ''
                    : ''
        },
        {
            formType: 'header',
            type: 'h5',
            value: IntlMessageValue(
                'de',
                'common.m22.header.sepcial.settings',
                Store
            )
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'select',
                    label: IntlMessageValue(
                        'de',
                        'common.m22.headline.with.media.label',
                        Store
                    ),
                    br: true,
                    name:
                        'elements[' + index + '][translation][headerWithMedia]',
                    legende: IntlMessageValue(
                        'de',
                        'common.m22.headline.with.media.legende',
                        Store
                    ),
                    selected:
                        newElement === false
                            ? element.translation !== undefined
                                ? element.translation.de.headerWithMedia
                                : 0
                            : 0,
                    options: [
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.activated',
                                Store
                            ),
                            value: '1'
                        },
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.deactivated',
                                Store
                            ),
                            value: '0'
                        }
                    ]
                },
                {
                    formType: 'select',
                    label: IntlMessageValue(
                        'de',
                        'common.m22.wrapper.label',
                        Store
                    ),
                    br: true,
                    name:
                        'elements[' +
                        index +
                        '][translation][wrappaaerIncludeHeader]',
                    legende: IntlMessageValue(
                        'de',
                        'common.m22.wrapper.legende',
                        Store
                    ),
                    selected:
                        newElement === false
                            ? element.translation !== undefined
                                ? element.translation.de.wrappaaerIncludeHeader
                                : 0
                            : 0,
                    options: [
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.activated',
                                Store
                            ),
                            value: '1'
                        },
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.deactivated',
                                Store
                            ),
                            value: '0'
                        }
                    ]
                },
                {
                    formType: 'select',
                    label: IntlMessageValue(
                        'de',
                        'common.m22.container.label',
                        Store
                    ),
                    br: true,
                    name: 'elements[' + index + '][translation][container]',
                    legende: IntlMessageValue(
                        'de',
                        'common.m22.wrapper.legende',
                        Store
                    ),
                    selected:
                        newElement === false
                            ? element.translation !== undefined
                                ? element.translation.de.container
                                : 0
                            : 0,
                    options: [
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.activated',
                                Store
                            ),
                            value: '1'
                        },
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.deactivated',
                                Store
                            ),
                            value: '0'
                        }
                    ]
                }
            ]
        },
        {
            formType: 'input',
            label: IntlMessageValue(
                'de',
                'common.m22.wrapper.css.class.label',
                Store
            ),
            type: 'text',
            placeholder: IntlMessageValue(
                'de',
                'common.m22.wrapper.css.class.placeholder',
                Store
            ),
            name: 'elements[' + index + '][translation][buttonWrapperClass]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.buttonWrapperClass
                        : ''
                    : ''
        }
    ]
    let newconfigSettings = configSettings.concat(
        basesettings(element, i, newElement, newelementIndex, Store)
    )
    const configCTA = [
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.button_text" />,
            type: 'text',
            max: 30,
            placeholder: IntlMessageValue('de', 'common.button_text', Store),
            name: 'elements[' + index + '][translation][cta_text]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.cta_text
                        : ''
                    : ''
        },
        {
            formType: 'url',
            label: <IntlMessage Store={Store} messageId="common.url" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.url', Store),
            name: 'elements[' + index + '][translation][url]',
            id: 'url' + index,
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.url
                        : ''
                    : ''
        }
    ]

    const configImage = [
        {
            formType: 'input',
            label: 'Media CSS-Klasse',
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.css_class', Store),
            name: 'elements[' + index + '][translation][media_class]',
            value:
                newElement === false ? element.translation.de.media_class : ''
        },
        {
            formType: 'select',
            label: 'Media Position',
            name: 'elements[' + index + '][translation][image_position]',
            selected:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.image_position
                        : ''
                    : '',
            options: [
                {
                    label: IntlMessageValue('de', 'common.left', Store),
                    value: 'links'
                },
                {
                    label: IntlMessageValue('de', 'common.right', Store),
                    value: 'rechts'
                }
            ]
        }
    ]

    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }

    return (
        <>
            <Box
                id={'module_text_image' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                style={{ border: '4px solid transparent' }}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    <IntlMessage
                        Store={Store}
                        messageId="module.text_with_image"
                    />,
                    'module_text_image',
                    newElement === false ? element.translation.de.text : '',
                    Status,
                    index,
                    'elementButton',
                    <IntlMessage
                        Store={Store}
                        messageId="module.text_with_image"
                    />,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <div
                    id={'preview-container_module_text_image' + index}
                    className={'preview-container ' + Status}
                >
                    {newElement === false ? (
                        <>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: element.previews
                                }}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    id={'mein_module_text_image' + index}
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={'element-body'}>
                        <div
                            id={'edit_module_text_image' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="module.text_with_image"
                                />
                            </Col>
                            <div id={'Teaser'}>module_text_image</div>
                            {PielersForm(newConfiguration)}
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'module_text_image'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        <Tabs
                            defaultActiveKey="Image"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            key={'tabindex'}
                        >
                            <Tab
                                eventKey="Image"
                                title={'Image'}
                                key={'Image'}
                                id={'Image'}
                                style={{
                                    backgroundColor: 'White',
                                    padding: '15px',
                                    marginTop: '-15px'
                                }}
                            >
                                {PielersForm(configImage)}
                            </Tab>
                            <Tab
                                eventKey="CTA"
                                title={'CTA'}
                                key={'CTA'}
                                id={'CTA'}
                                style={{
                                    backgroundColor: 'White',
                                    padding: '15px',
                                    marginTop: '-15px'
                                }}
                            >
                                {PielersForm(configCTA)}
                            </Tab>
                            <Tab
                                eventKey="Einstellungen"
                                title={
                                    <IntlMessage
                                        Store={Store}
                                        messageId="settings.settings.header"
                                        locale={'de'}
                                    />
                                }
                                key={'Einstellungen'}
                                style={{
                                    backgroundColor: 'White',
                                    padding: '15px',
                                    marginTop: '-15px'
                                }}
                            >
                                {PielersForm(newconfigSettings)}
                            </Tab>
                        </Tabs>
                    </div>

                    {newElement === false ? (
                        CopyElements(
                            element.id,
                            <IntlMessage
                                messageId="module.text_with_image"
                                Store={Store}
                            />,
                            Store
                        )
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default module_text_imageWebBuilder
