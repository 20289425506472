import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Form from 'react-bootstrap/Form'
import { buildDoc } from '../../../../@WUM/core/Elements/index'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import InputGroup from 'react-bootstrap/InputGroup'
import FormUploadFile from '../../../../@WUM/core/form-serilizer/elements/FormUploadFile'

const Forms = (response: any = [], mode: any = 'new') => {
    return (
        <>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
                key={'tabindex'}
            >
                <Tab
                    eventKey="home"
                    title={'Allgemein'}
                    key={'allgemein'}
                    id={'allgemein'}
                >
                    <h2>Allgemein</h2>
                    <h3>Vorbereitung des EWIV-Statues</h3>
                    <p>Ihre Angaben werden streng vertraulich behandelt!</p>
                    <h4>Daten EWIV</h4>
                    <hr />
                    <Row>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="companyName"
                            >
                                <Form.Label>Firmenname:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'companyName'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].companyName
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="operationDate"
                            >
                                <Form.Label>Gründungsdatum:</Form.Label>
                                <Form.Control
                                    type="date"
                                    name={'operationDate'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].operationDate
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <h4>Daten EWIV</h4>
                    <hr />
                    <Row>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="companyStreet"
                            >
                                <Form.Label>Straße:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'companyStreet'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].companyStreet
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="companyPostcode"
                            >
                                <Form.Label>Postleitzahl:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'companyPostcode'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].companyPostcode
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="companyCity"
                            >
                                <Form.Label>Ort:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'companyCity'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].companyCity
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <hr />

                    <Form.Group className="mb-3" controlId="buisness">
                        <Form.Label>
                            Wofür wollen Sie die Gelder in der EWIV investieren?
                            Denken Sie gern 10 - 20 Jahre voraus, welche
                            Tätigkeiten die Hilfsgesellschaft übernehmen soll.
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            name={'buisness'}
                            rows={5}
                            defaultValue={
                                mode === 'new' ? '' : response[0].business
                            }
                        />
                    </Form.Group>
                    <h4>
                        Welchen Geschäftsführer soll die EWIV haben mit
                        Anschrift
                    </h4>
                    <hr />
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="first_name">
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'first_name'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].first_name
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="last_name">
                                <Form.Label>Vorname:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'last_name'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].last_name
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="street">
                                <Form.Label>Straße:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'street'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new' ? '' : response[0].street
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="postcode">
                                <Form.Label>PLZ:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'postcode'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].postcode
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>Ort:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'city'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new' ? '' : response[0].city
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="dob">
                                <Form.Label>Geburtstag:</Form.Label>
                                <Form.Control
                                    type="date"
                                    name={'dob'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new' ? '' : response[0].dob
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="pob">
                                <Form.Label>Geburtsort:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'pob'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new' ? '' : response[0].pob
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Tab>
                <Tab
                    eventKey="Dokumente"
                    title={'Dokumente'}
                    key={'Dokumente'}
                    id={'Dokumente'}
                >
                    <h2>Dokumente</h2>
                    {mode === 'new' ? (
                        <></>
                    ) : (
                        <Row>
                            <Col className={'col-6'}>
                                <br />
                                <Form.Group className="mb-3" controlId="form">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">
                                            Art auswählen:
                                        </InputGroup.Text>
                                        <Form.Select
                                            id={'field_context'}
                                            aria-label="Default select example"
                                            name={'form'}
                                            onClick={(e) =>
                                                unlock(e, 'ewivupload')
                                            }
                                        >
                                            <option />
                                            <option value={'service'}>
                                                Dienstleistungauftrag
                                            </option>
                                            <option value={'extra'}>
                                                Extra
                                            </option>
                                        </Form.Select>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col className={'col-6'}>
                                {' '}
                                <FormUploadFile
                                    data={{
                                        formType: 'formFileBucket',
                                        onclick: true,
                                        onclick_context:
                                            'pielers_element_text_image',
                                        append: 'preview_media',
                                        experiment: true,
                                        multi: false,
                                        fieldcontext: 'field_context',
                                        src: 'ewiv',
                                        addedRoute: 'ewiv/',
                                        Buckettype: true,
                                        send_id: response[0].id,
                                        id: 'ewivupload',
                                        url: 'ewiv/',
                                        type: 'file',
                                        size: {
                                            xl: 10,
                                            md: 10,
                                            sm: 10
                                        }
                                    }}
                                    Store={'de'}
                                />
                            </Col>
                        </Row>
                    )}
                    {mode !== 'new' ? (
                        response[0].medias !== null &&
                        response[0].medias !== undefined ? (
                            buildDoc(response[0].medias)
                        ) : (
                            <></>
                        )
                    ) : (
                        <></>
                    )}
                </Tab>
            </Tabs>
        </>
    )
}

export default Forms

const unlock = (e: any, name: any) => {
    let fileField = document.getElementById(name) as HTMLInputElement
    if (
        e.target.value !== undefined &&
        e.target.value !== null &&
        e.target.value !== ''
    ) {
        fileField.removeAttribute('disabled')
    } else {
        fileField.setAttribute('disabled', 'true')
    }
}
