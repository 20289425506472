import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ResponseMerchantDetail } from '../../../API/response/orders/orders'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { SpinnerJSX } from '../../../../../../@WUM/core/component/const/Spinner'
import Button from 'react-bootstrap/Button'
import {
    saveDataIndividualGet,
    saveDataIndividualPut
} from '../../../../../../@WUM/core/RestFullApi/ApiHook'
import { notify_save } from '../../../../../../@WUM/core/component/const/notification.service'
import { error } from '../../../../../../@WUM/core/Elements/index'
import Paper from '@material-ui/core/Paper'
import Form from 'react-bootstrap/Form'
import { displayElement, IntlMessage, Download } from '../../../../../../@WUM/core/Elements/index'
import { useSelector } from 'react-redux'

const OrdersDetailMercahnt = (value: any) => {
    const { id } = useParams<{ id: string }>()
    const history = useNavigate()
    const response2 = ResponseMerchantDetail(id)
    const [cancelState, setcancelState] = React.useState(false)
    const [cancelID, setCancelID] = React.useState(0)
    const [cancelshipping_state, setCancelshipping_state] =
        React.useState(false)
    if (cancelState !== false && cancelID !== 0) {
        setcancelState(false)
        setCancelID(0)
    }
    const Store = useSelector((state: any) => state)

    const createFiles = (datas: any) => {
        let ID = datas

        saveDataIndividualGet('invoice/create/' + ID + '/')
            .then((data: any) => {
                setCancelshipping_state(true)
                notify_save(Store)
            })
            .catch((e) => {
                error(e)
            })
    }

    const setternew = (id: any) => {
        let message = (
            document.getElementById('message') as HTMLTextAreaElement
        ).value
        let ItemHolder = []
        let send = false
        var ele = document.getElementsByClassName('storno')
        for (var i = 0; i < ele.length; i++) {
            var element = ele[i].children[0] as HTMLInputElement
            if (element.checked === true) {
                ItemHolder.push(element.value)
                send = true
            }
        }

        if (message === '') {
            alert('Sie müssen eine Nachricht eingeben')
            return
        }

        if (send === false) {
            alert('Sie müssen ein Produkt zum Stornieren anklicken')
            return
        }

        let Body = { message: message, item: ItemHolder }

        saveDataIndividualPut('/Orders/Order/Item/Cancel/' + id + '/', Body)
            .then((data: any) => {
                notify_save(Store)
                setTimeout(() => window.location.reload(), 3000)
            })
            .catch((e) => {
                error(e)
            })
    }

    const sum = (currency: string, wert: any) => {
        let summe
        summe = wert / 100

        let ConvertSum = Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2
        }).format(summe)
        return ConvertSum
    }

    const total = (
        unit: number,
        quantity: number,
        total: number,
        tax: boolean = false,
        now: any = false
    ) => {
        if (now === true) {
            if (tax === false) {
                return sum('EUR', total)
            } else {
                let tax = (total / 119) * 19
                return sum('EUR', tax)
            }
        }

        let totalTMP = unit * quantity

        if (totalTMP !== total) {
            if (tax === false) {
                return sum('EUR', totalTMP)
            } else {
                let tax = (totalTMP / 119) * 19
                return sum('EUR', tax)
            }
        } else {
            if (tax === false) {
                return sum('EUR', total)
            } else {
                let tax = (total / 119) * 19
                return sum('EUR', tax)
            }
        }
    }

    const tax = (sum: number) => {
        let tax = (sum / 119) * 19
        return tax
    }

    const item = (
        data: any,
        merchant: any,
        y: any,
        ItemHolderbill: any,
        cancelState: any,
        setcancelState: any,
        setCancelID: any
    ) => {
        const CancelButton = (data: any, I: any) => {
            let cancel
            if (data[I].Product.shippable_state === 'cancelled') {
                cancel = <>Storniert</>
            } else {
                cancel = (
                    <>
                        {' '}
                        <Form.Group
                            controlId={'checkbox'}
                            key={'checkbox'}
                            className="mb-3"
                        >
                            <Form.Check
                                name={'stornoElement'}
                                value={data[0].ItemID}
                                className={'storno'}
                                label={'Stornieren'}
                                aria-label="option 1"
                            />
                        </Form.Group>
                    </>
                )
            }
            return cancel
        }

        const setItems = (data: any) => {
            let ItemHolder = []
            for (let I = 0; I < data.length; I++) {
                ItemHolder.push(
                    <>
                        <hr />
                        <Row>
                            <Col xl={5}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: data[I].VariantTranlsation.name
                                    }}
                                />{' '}
                                {(data[I].Variant.code !== null && data[I].Variant.code!== undefined ? <>({data[I].Variant.code})</> : <></>)}

                            </Col>
                            <Col xl={2}>Menge: {data[I].Product.quantity}</Col>
                            <Col xl={1}>
                                {sum('EUR', data[I].Product.unit_price)}
                            </Col>
                            <Col xl={1}>
                                {total(
                                    data[I].Product.unit_price,
                                    data[I].Product.quantity,
                                    data[I].Product.total
                                )}
                            </Col>
                            <Col xl={3}>{CancelButton(data, I)}</Col>
                        </Row>
                    </>
                )
            }
            return ItemHolder
        }

        return (
            <>
                <Row>
                    <Col xl={12} className={'mb-4'}>
                        <br />
                        <h3>
                            <IntlMessage
                                Store={Store}
                                messageId="common.delivery"
                            />{' '}
                            #{y + 1}
                        </h3>

                        {/*  <h3>Paket #{y + 1}</h3>*/}

                        {setItems(data)}
                        <hr />
                    </Col>
                    {/* <Col xl={5}>{ItemHolderbill[y]}</Col>*/}
                </Row>
                <hr
                    style={{
                        color: '#000000',
                        backgroundColor: '#000000',
                        height: 5
                    }}
                />
            </>
        )
    }

    const itembill = (data: any) => {
        return
    }

    let ItemHolderbill = []
    if (response2?.Order.Items !== undefined) {
        for (let x = 0; x < response2?.Order.Items.length; x++) {
            let singleItemHolder = []
            singleItemHolder.push({ Product: response2?.Order.Items[x].order })
            ItemHolderbill.push(itembill(singleItemHolder))
        }
    }

    let ItemHolder = []
    if (response2?.Order.Items !== undefined) {
        for (let x = 0; x < response2?.Order.Items.length; x++) {
            let singleItemHolder = []
            singleItemHolder.push({
                Product: response2?.Order.Items[x].order,
                Productcode: response2?.Order.Items[x].productData.code,
                ItemID: response2?.Order.Items[x].order.id,
                Variant: response2?.Order.Items[x].vari,
                VariantTranlsation: response2?.Order.Items[x].vari_transation
            })

            ItemHolder.push(
                item(
                    singleItemHolder,
                    'test',
                    x,
                    ItemHolderbill,
                    cancelState,
                    setcancelState,
                    setCancelID
                )
            )
        }
    }

    if (response2?.hasOwnProperty('Order') === true) {
        let paymentstate: any = {
            paid: {
                label: <IntlMessage Store={Store} messageId="common.paid" />,
                color: 'success'
            },
            ready: {
                label: <IntlMessage Store={Store} messageId="common.pending" />,
                color: 'warning'
            },
            new: {
                label: <IntlMessage Store={Store} messageId="common.pending" />,
                color: 'warning'
            },
            awaiting_payment: {
                label: <IntlMessage Store={Store} messageId="common.pending" />,
                color: 'warning'
            },
            cancelled: {
                label: (
                    <IntlMessage Store={Store} messageId="common.cancelled" />
                ),
                color: 'danger'
            },
            addressed: {
                label: (
                    <IntlMessage Store={Store} messageId="common.completed" />
                ),
                color: 'success'
            }
        }

        let Orderstate: any = {
            new: {
                label: (
                    <IntlMessage Store={Store} messageId="common.new_order" />
                ),
                color: 'warning'
            },
            fulfilled: {
                label: (
                    <IntlMessage Store={Store} messageId="common.completed" />
                ),
                color: 'success'
            },
            completed: {
                label: (
                    <IntlMessage Store={Store} messageId="common.completed" />
                ),
                color: 'success'
            },
            cancelled: {
                label: (
                    <IntlMessage Store={Store} messageId="common.cancelled" />
                ),
                color: 'danger'
            },
            addressed: {
                label: (
                    <IntlMessage Store={Store} messageId="common.completed" />
                ),
                color: 'success'
            }
        }

        let buttonShipping
        if (response2 !== undefined) {
            if (response2.Order.shipping_state === 'created') {
                buttonShipping = <></>
            } else if (
                response2.Order.shipping_state !== 'created' &&
                response2.Order.shipping_state !== 'cancelled' &&
                cancelshipping_state === false
            ) {
                buttonShipping = (
                    <>
                        <Button
                            type={'button'}
                            variant={'success'}
                            className={'buttonOrder'}
                            onClick={() => createFiles(response2?.Order.id)}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.create_delivery"
                            />
                        </Button>
                    </>
                )
            }
        }

        let invoice
        if (
            response2?.Order.merchant_invoice_id !== null &&
            response2?.Order.invoice !== null
        ) {
            if (
                response2?.Order.invoice !== undefined &&
                response2?.Order.invoice !== null
            ) {
                if (
                    response2?.Order.invoice.cdn_url !== undefined &&
                    response2?.Order.invoice.cdn_url !== null
                ) {
                    invoice = (
                        <>
                            <Download
                                pdfId={response2?.Order.invoice.id}
                                name={response2?.Order.invoice.name}
                            />
                        </>
                    )
                }
            }
        } else {
            invoice = <></>
        }

        let shippingcost = 0
        if (response2.Order.hasOwnProperty('Items')) {
            for (let x = 0; x < response2.Order.Items.length; x++) {
                shippingcost += response2.Order.Items[x].shippingcost
            }
        } else {
            for (let x = 0; x < response2.Order.items.length; x++) {
                shippingcost += response2.Order.items[x].shippingcost
            }
        }
        let tmpTotal = response2?.Order.items_total + shippingcost

        return (
            <div
                key={'head'}
                style={{ backgroundColor: '#FFFFFF', padding: '20px' }}
            >
                <Row>
                    <Col xl={6}>
                        <h2>
                            #{response2?.Order.number} -{' '}
                            <IntlMessage
                                Store={Store}
                                messageId="common.ordered_on"
                            />{' '}
                            {response2?.Order.checkout_completed_at}
                        </h2>
                    </Col>
                    <Col xl={6}>
                        <div className={'float-end'}>
                            <Button
                                variant="light"
                                style={{ float: 'left', marginLeft: '15px' }}
                                onClick={() => history(-1)}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.back"
                                />
                            </Button>
                            {buttonShipping}
                            <Button
                                type={'button'}
                                variant={
                                    Orderstate[response2?.Order.state].color
                                }
                                className={'disabled buttonOrder'}
                            >
                                {Orderstate[response2?.Order.state].label}
                            </Button>
                            <Button
                                type={'button'}
                                variant={
                                    paymentstate[response2?.Order.payment_state]
                                        .color
                                }
                                className={'disabled buttonOrder'}
                            >
                                {
                                    paymentstate[response2?.Order.payment_state]
                                        .label
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xl={8}>
                        {ItemHolder}
                        <Button
                            type={'button'}
                            variant={'primary'}
                            onClick={() =>
                                displayElement('ContentStorno', 'block')
                            }
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.cancel_item"
                            />
                        </Button>
                    </Col>
                    <Col xl={4}>
                        <h2>
                            <IntlMessage
                                Store={Store}
                                messageId="common.customer"
                            />
                        </h2>
                        <div
                            style={{
                                background: '#f2f2f2',
                                borderRadius: '3px',
                                marginBottom: '20px',
                                padding: '20px',
                                position: 'relative'
                            }}
                        >
                            {response2?.Order.customer.email}
                        </div>
                        <br />
                        <br />
                        <h2>
                            <IntlMessage
                                Store={Store}
                                messageId="common.billing_address"
                            />
                        </h2>
                        <div
                            style={{
                                background: '#f2f2f2',
                                borderRadius: '3px',
                                marginBottom: '20px',
                                padding: '20px',
                                position: 'relative'
                            }}
                        >
                            {response2?.Order.billingAddress.first_name}{' '}
                            {response2?.Order.billingAddress.last_name}
                            <br />
                            {response2?.Order.billingAddress.street}
                            <br />
                            {response2?.Order.billingAddress.country_code}{' '}
                            {response2?.Order.billingAddress.postcode}{' '}
                            {response2?.Order.billingAddress.city}
                            <br />
                            <strong>
                                <a
                                    href={
                                        'tel:+49' +
                                        (response2 !== undefined && response2 !== null && response2?.Order.shippingAddresse.phone_number !== undefined && response2?.Order.shippingAddresse.phone_number !== null ? response2?.Order.shippingAddresse?.phone_number.slice(
                                            1
                                        ) : '')

                                    }
                                >
                                    {
                                        response2?.Order.billingAddress
                                            .phone_number
                                    }
                                </a>
                            </strong>
                            <br />
                        </div>
                        <br />
                        <br />
                        <h2>
                            <IntlMessage
                                Store={Store}
                                messageId="common.delivery_address"
                            />
                        </h2>
                        <div
                            style={{
                                background: '#f2f2f2',
                                borderRadius: '3px',
                                marginBottom: '20px',
                                padding: '20px',
                                position: 'relative'
                            }}
                        >
                            {response2?.Order.shippingAddresse.first_name}{' '}
                            {response2?.Order.shippingAddresse.last_name}
                            <br />
                            {response2?.Order.shippingAddresse.street}
                            <br />
                            {
                                response2?.Order.shippingAddresse.country_code
                            }{' '}
                            {response2?.Order.shippingAddresse.postcode}{' '}
                            {response2?.Order.shippingAddresse.city}
                            <br />
                            <strong>
                                <a
                                    href={
                                        'tel:+49' +
                                        (response2 !== undefined && response2 !== null && response2?.Order.shippingAddresse.phone_number !== undefined
                                        && response2?.Order.shippingAddresse.phone_number !== null ? response2?.Order.shippingAddresse.phone_number.slice(
                                            1
                                        ) : '')
                                    }
                                >
                                    {
                                        response2?.Order.shippingAddresse
                                            .phone_number
                                    }
                                </a>
                            </strong>
                            <br />
                        </div>
                        <br />
                        <br />
                        <h2>
                            <IntlMessage
                                Store={Store}
                                messageId="customer.note"
                            />
                        </h2>
                        <div
                            style={{
                                background: '#f2f2f2',
                                borderRadius: '3px',
                                marginBottom: '20px',
                                padding: '20px',
                                position: 'relative'
                            }}
                        >
                            {response2?.Order.notes}
                        </div>
                        <br />
                        <br />
                        {/*
                        ***Todo Bist klärung ausblenden danach nach neuen Muster einblenben ***
                        <h2>
                            <IntlMessage
                                Store={Store}
                                messageId="common.total"
                            />
                        </h2>
                        <div
                            style={{
                                background: '#f2f2f2',
                                borderRadius: '3px',
                                marginBottom: '20px',
                                padding: '20px',
                                position: 'relative'
                            }}
                        >
                            <Row>
                                <Col>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.price.sub"
                                    />
                                </Col>
                                <Col>
                                    {' '}
                                    {sum(
                                        response2?.Order.payment.currency_code,
                                        response2?.Order.items_total
                                    )}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.customer_discount"
                                    />
                                </Col>
                                <Col>
                                    -{' '}
                                    {Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                        minimumFractionDigits: 2
                                    }).format(
                                        response2?.Order.coupon_amount / 100
                                    )}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.operator_credit"
                                    />
                                </Col>
                                <Col>
                                    {Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                        minimumFractionDigits: 2
                                    }).format(
                                        response2?.Order.coupon_amount / 100
                                    )}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.shipping.costs"
                                    />
                                </Col>
                                <Col>
                                    {total(
                                        shippingcost,
                                        1,
                                        shippingcost,
                                        false,
                                        true
                                    )}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.taxes_included"
                                    />
                                </Col>
                                <Col>
                                    {sum(
                                        response2?.Order.payment.currency_code,
                                        tax(response2?.Order.items_total)
                                    )}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.price.total"
                                    />
                                </Col>
                                <Col>
                                    {sum(
                                        response2?.Order.payment.currency_code,
                                        tmpTotal
                                    )}
                                </Col>
                            </Row>
                        </div>
*/}
                        <h2>
                            <IntlMessage
                                Store={Store}
                                messageId="common.files"
                            />
                        </h2>
                        <div
                            style={{
                                background: '#f2f2f2',
                                borderRadius: '3px',
                                marginBottom: '20px',
                                padding: '20px',
                                position: 'relative'
                            }}
                        >
                            {invoice}
                        </div>
                    </Col>
                    <Paper
                        id={'ContentStorno'}
                        className={'modalviewContent'}
                        style={{
                            display: 'none',
                            width: '450px',
                            padding: '10px',
                            height: 'auto',
                            overflow: 'hidden',
                            left: '25%',
                            zIndex: 1
                        }}
                    >
                        <Row style={{ padding: '10px' }}>
                            <Form.Group
                                className="mb-3"
                                controlId={'Form.ControlTextarea'}
                                key={'Form.ControlTextarea'}
                            >
                                <Form.Label>Stornierungsgrund</Form.Label>
                                <Form.Control
                                    name={'message'}
                                    id={'message'}
                                    as={'textarea'}
                                    rows={10}
                                    maxLength={500}
                                />
                            </Form.Group>
                        </Row>
                        <hr />
                        <div>
                            <br />
                            <Button
                                type={'button'}
                                variant={'primary'}
                                onClick={() =>
                                    displayElement('ContentStorno', 'none')
                                }
                            >
                                Schließen
                            </Button>
                            <Button
                                type={'button'}
                                variant={'danger'}
                                className={'float-end'}
                                onClick={() => setternew(response2?.Order.id)}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.cancel_item"
                                />
                            </Button>
                            <div style={{ clear: 'both' }} />
                        </div>
                    </Paper>
                </Row>
            </div>
        )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default OrdersDetailMercahnt
