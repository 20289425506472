import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Form from 'react-bootstrap/Form'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import InputGroup from 'react-bootstrap/InputGroup'

const Forms = (
    response: any = [],
    mode: any = 'new',
    fetch: any = [],
    fetch2: any = [],
    fetch3: any = []
) => {
    return (
        <>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
                key={'tabindex'}
            >
                <Tab
                    eventKey="home"
                    title={'Allgemein'}
                    key={'allgemein'}
                    id={'allgemein'}
                >
                    {(mode === 'new' ? <></> :<>
                    <Form.Control
                        type="hidden"
                        name={'customer_id'}
                        defaultValue={
                            mode === 'new'
                                ? ''
                                : response[0].customer.id
                        }
                    />
                    <Form.Control
                        type="hidden"
                        name={'address_id'}
                        defaultValue={
                            mode === 'new'  ? ''
                            : response[0].address !== undefined ? response[0].address[0].id
                            : undefined
                        }
                    />

                    <Form.Control
                        type="hidden"
                        name={'userId'}
                        defaultValue={
                            mode === 'new'
                                ? ''
                                : response[0].id
                        }
                    />
                    </>)}
                    <h3>Allgemein</h3>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>Kunde:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'username'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].username
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="is_active">
                                <Form.Label>Status:</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    name={'is_active'}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].is_active
                                    }
                                >
                                    <option value={1}>Aktiviert</option>
                                    <option value={0}>Deaktiviert</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h3>Mandantendaten</h3>
                    <hr />
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="firstName">
                                <Form.Label>Vorname:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'firstName'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].customer.firstName
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="lastName">
                                <Form.Label>Nachname:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'lastName'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].customer.lastName
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>E-Mail:</Form.Label>
                                <Form.Control
                                    type="email"
                                    name={'email'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].customer.email
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="phoneNumber"
                            >
                                <Form.Label>Telefonnummer:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'phoneNumber'}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].customer.phoneNumber
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <h3>Adressdaten</h3>
                    <hr />
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="company">
                                <Form.Label>Firmenname:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'company'}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].address !== undefined ? response[0].address[0].company
                                            : undefined
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="street">
                                <Form.Label>Straße:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'street'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].address !== undefined ? response[0].address[0].street
                                            : undefined
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="postcode">
                                <Form.Label>Postleitzahl:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'postcode'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].address !== undefined ? response[0].address[0].postcode
                                            : undefined
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>Stadt:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'city'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].address !== undefined ? response[0].address[0].city
                                            : undefined

                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="country_code"
                            >
                                <Form.Label>Ländercode:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'country_code'}
                                    required={true}
                                    pattern="[A-Za-z]{2}" title="2 Stelliger Ländercode. Hier eine Liste https://de.wikipedia.org/wiki/ISO-3166-1-Kodierliste "
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].address !== undefined ? response[0].address[0].country_code
                                            : undefined

                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Tab>

            </Tabs>
        </>
    )
}

export default Forms

const buildFilesbyBusiness = (business: any, key: any) => {
    let companyHolder = []
    for (let b = 0; b < business.length; b++) {
        if (key === business[b].type) {
            companyHolder.push(
                <>
                    <Col className={'col-4'}>
                        <Card style={{ width: '400', height: '550' }}>
                            <Card.Body>
                                <Card.Title>
                                    {business[b].companyName}
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    Hinterlegtes Dokument
                                </Card.Subtitle>
                                <Card.Text className={'text-center'}>
                                    {business[b].media.content_type.includes(
                                        'image'
                                    ) ? (
                                        <Image
                                            src={business[b].media.cdn_url}
                                            style={{ width: 'fit-content' }}
                                        />
                                    ) : (
                                        <object
                                            data={business[b].media.cdn_url}
                                            width={'300px'}
                                            height={'400px'}
                                            aria-label={'pdf'}
                                            className={'mb-3'}
                                        />
                                    )}
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">
                                    {business[b].docType}: {business[b].name}
                                </small>
                            </Card.Footer>
                        </Card>
                        <br />
                    </Col>
                </>
            )
        }
    }
    return (
        <>
            <Row>{companyHolder}</Row>
        </>
    )
}

const confirmAction = (e: any) => {
    if ((e.target as HTMLInputElement).readOnly === true) {
        const response = window.confirm(
            'Sind Sie sicher, dass Sie die Partnernummer ändern wollen. Das hat große Auswirkungen!'
        )

        if (response) {
            alert('Änderung durchgeführt auf Anfrage des Nutzer.')
            ;(e.target as HTMLInputElement).readOnly = false
        } else {
            alert('Änderung abgebrochen durch Nutzer.')
        }
    }
}

const changer = (e:any,element:any) => {

    for(let el=0;el<element.length;el++) {
        if(e.target.value === '1') {
            let tmp = document.getElementById(element[el].id) as HTMLInputElement;
            tmp.readOnly = false;
        } else {
            let tmp = document.getElementById(element[el].id) as HTMLInputElement;
            tmp.readOnly = true;
        }

    }

}
