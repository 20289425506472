import React from 'react'
import PielersForm from '../../index'
import { Button, Tabs } from 'react-bootstrap'
import { base, basesettings } from './base'
import Tab from 'react-bootstrap/Tab'
import {MergeTwoObjecttoArray} from '../../../Function/index'
import { CopyElements, IntlMessage, IntlMessageValue, SunEditorBig, WebBuilderElementsHead } from '../../../Elements/index'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ReactDOM from 'react-dom'
import { defaultIMG } from '../../elements/formFile'
import Box from '@mui/material/Box'

function hexagonWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any,
    galerie?: any,
    galerieItems?: any
) {
    localStorage.removeItem('Iterator')

    const index = newElement === false ? i : newelementIndex
    const fake_headlines = [1, 2, 3, 4, 5, 6].map((n) => ({
        label: IntlMessageValue(
            'de',
            'module.fake_headline_n',
            Store,
            'replace',
            '[n]',
            n
        ),
        value: n
    }))

    const headlines = [1, 2, 3, 4, 5, 6].map((n) => ({
        label: IntlMessageValue(
            'de',
            'module.headline_n',
            Store,
            'replace',
            '[n]',
            n
        ),
        value: n + 6
    }))

    let options = MergeTwoObjecttoArray(fake_headlines, headlines)

    const configuration = [
        {
            formType: 'select',
            label: (
                <IntlMessage Store={Store} messageId="module.headline_type" />
            ),
            name: 'elements[' + index + '][headline_type]',
            legende: <IntlMessage Store={Store} messageId="common.status" />,
            selected: newElement === false ? element.headline_type : '',
            options: options
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.headline" />,
            name: 'elements[' + index + '][translation][headline]',
            type: 'text',
            placeholder: 'lang[common.insert_headline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.headline
                        : ''
                    : ''
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/hexagon.html'
        }
    ]

    localStorage.setItem(
        'Iterator_hexa' + index,
        newElement === false
            ? element.hasOwnProperty('items')
                ? element.items.length
                : 0
            : 0
    )

    const newItemGenerate = (
        element: any,
        index: any,
        data: any,
        Store: any,
        galerie: any,
        galerieItems: any
    ) => {
        let Items = []
        let x
        if (
            localStorage.getItem('Iterator_hexa' + index) !== undefined &&
            localStorage.getItem('Iterator_hexa' + index) !== null
        ) {
            x = localStorage.getItem('Iterator_hexa' + index)
        } else {
            x = element.items.length
        }

        Items.push({
            formType: 'multiplyinRow',
            RowId: 'hexagonRow_' + x + '_' + index,
            singelCol: true,
            class: 'mb-3 boxShadowCard card hexacard',
            colsize: 4,
            nonCol: true,
            cols: [
                {
                    formType: 'input',
                    name: 'elements[' + index + '][items][' + x + '][id]',
                    type: 'hidden',
                    value: 0
                },
                {
                    formType: 'multiply',
                    cols: [
                        {
                            formType: 'formFile',
                            onclick: true,
                            id: 'media_titleFormField' + index + x,
                            onclick_context: 'pielers_element_title_image',
                            Gallery: galerie,
                            galerieItems: galerieItems,
                            expandfile: index + 'galerie' + x,
                            imgupload: true,
                            experiment: true,
                            imgsrc: defaultIMG(),
                            label: 'Bild',
                            width: 170,
                            height: 170,
                            append:
                                'elements[' +
                                index +
                                '][items][' +
                                x +
                                '][media_id]',
                            src: 'media_title_image_' + index + x,
                            size: {
                                xl: 6,
                                md: 6,
                                sm: 6
                            }
                        },

                        {
                            formType: 'formFile',
                            onclick: true,
                            id: 'media_hover_titleFormField' + index + x,
                            onclick_context: 'pielers_element_title_image',
                            Gallery: galerie,
                            galerieItems: galerieItems,
                            expandfile: index + 'galerieHover' + x,
                            imgupload: true,
                            experiment: true,
                            imgsrc: defaultIMG(),
                            label: 'Hover Bild',
                            width: 170,
                            height: 170,
                            append:
                                'elements[' +
                                index +
                                '][items][' +
                                x +
                                '][media_hover_id]',
                            src: 'media_hover_title_image_' + index + x,
                            size: {
                                xl: 6,
                                md: 6,
                                sm: 6
                            }
                        }
                    ]
                },

                {
                    formType: 'input',
                    name: 'elements[' + index + '][items][' + x + '][media_id]',
                    id: 'elements[' + index + '][items][' + x + '][media_id]',
                    visible: true,
                    label: 'mediaID',
                    type: 'hidden',
                    placeholder: 'mediaID',
                    value: ''
                },
                {
                    formType: 'input',
                    name:
                        'elements[' +
                        index +
                        '][items][' +
                        x +
                        '][media_hover_id]',
                    id:
                        'elements[' +
                        index +
                        '][items][' +
                        x +
                        '][media_hover_id]',
                    visible: true,
                    label: 'media_hoverID',
                    type: 'hidden',
                    placeholder: 'media_hoverID',
                    value: ''
                },
                SunEditorBig(
                    '',
                    'text',
                    true,
                    data,
                    'elements[' + index + '][items][' + x + '][text]',
                    IntlMessageValue('de', 'common.edit_text', Store),
                    '',
                    '',
                    '',
                    '',
                    'inline',
                    150
                ),
                {
                    formType: 'input',
                    label: <IntlMessage Store={Store} messageId="common.url" />,
                    type: 'text',
                    placeholder: IntlMessageValue('de', 'common.url', Store),
                    name: 'elements[' + index + '][items][' + x + '][link]',
                    value: ''
                },
                {
                    formType: 'multiply',
                    cols: [
                        {
                            formType: 'select',
                            label: 'Animation',
                            name:
                                'elements[' +
                                index +
                                '][items][' +
                                x +
                                '][animation]',
                            selected: '',
                            options: [
                                {
                                    label: IntlMessageValue(
                                        'de',
                                        'common.activated',
                                        Store
                                    ),
                                    value: '1'
                                },
                                {
                                    label: IntlMessageValue(
                                        'de',
                                        'common.deactivated',
                                        Store
                                    ),
                                    value: '0'
                                }
                            ]
                        },
                        {
                            formType: 'input',
                            label: (
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.css_class"
                                />
                            ),
                            type: 'text',
                            placeholder: IntlMessageValue(
                                'de',
                                'common.css_class',
                                Store
                            ),
                            name:
                                'elements[' +
                                index +
                                '][items][' +
                                x +
                                '][class]',
                            value: ''
                        }
                    ]
                },

                {
                    formType: 'select',
                    label: 'Type',
                    name:
                        'elements[' + index + '][items][' + x + '][background]',
                    selected: '',
                    options: [
                        {
                            label: '',
                            value: ''
                        },
                        {
                            label: 'background-cover',
                            value: 'background-cover'
                        },
                        {
                            label: 'background-contain',
                            value: 'background-contain'
                        },
                        {
                            label: 'background-contain flip-box-front-text',
                            value: 'background-contain flip-box-front-text'
                        },
                        {
                            label: 'only_text',
                            value: 'only_text'
                        }
                    ]
                }
            ]
        })

        let newConfiguration = Items
        let newElement = <>{PielersForm(newConfiguration)}</>
        let temp = document.createElement('div')
        temp.classList.add('col-md-4')
        ReactDOM.render(newElement, temp)
        let ele = document.getElementById(
            'hexagonRow' + index
        ) as HTMLDivElement
        if (ele !== null) {
            ele.appendChild(temp)
            // @ts-ignore

            if (
                localStorage.getItem('Iterator_hexa' + index) !== undefined &&
                localStorage.getItem('Iterator_hexa' + index) !== null
            ) {
                let iterator =
                    parseInt(
                        String(
                            localStorage.getItem(
                                'Iterator_hexa' + index
                            ) as unknown as number
                        )
                    ) + 1
                localStorage.setItem('Iterator_hexa' + index, String(iterator))
            } else {
                localStorage.setItem('Iterator_hexa' + index, x + 1)
            }
        }
    }

    let newConfiguration = configuration

    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }

    return (
        <>
            <Box
                id={'hexagon' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    'Hexagon',
                    'hexagon',
                    '',
                    Status,
                    index,
                    'elementButton',
                    'hexagon',
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <div
                    id={'preview-container_hexagon' + index}
                    className={'preview-container ' + Status}
                    style={{ display: newElement === false ? 'block' : 'none' }}
                >
                    {newElement === false ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: element.previews
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    id={'mein_hexagon' + index}
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={'element-body'}>
                        <div
                            id={'edit_hexagon' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <div id={'Teaser'}>text</div>
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                Hexagon
                            </Col>
                            <Button
                                variant={'success'}
                                onClick={() =>
                                    newItemGenerate(
                                        element,
                                        index,
                                        data,
                                        Store,
                                        galerie,
                                        galerieItems
                                    )
                                }
                                className={'mb-3 '}
                            >
                                Neues Hexagon hinzufügen
                            </Button>
                            <br />
                            <Row id={'hexagonRow' + index}>
                                {buildItems(
                                    element,
                                    index,
                                    newElement,
                                    data,
                                    Store,
                                    galerie,
                                    galerieItems
                                )}
                            </Row>
                            {PielersForm(
                                base(element, i, newElement, newelementIndex)
                            )}
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'hexagon'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            key={'tabindex'}
                        >
                            <Tab
                                eventKey="home"
                                title={
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.general"
                                    />
                                }
                                key={'allgemein'}
                                id={'allgemein'}
                                style={{
                                    backgroundColor: 'White',
                                    padding: '15px',
                                    marginTop: '-15px'
                                }}
                            >
                                {PielersForm(newConfiguration)}
                            </Tab>
                            <Tab
                                eventKey="Einstellungen"
                                title={
                                    <IntlMessage
                                        Store={Store}
                                        messageId="settings.settings.header"
                                        locale={'de'}
                                    />
                                }
                                key={'Einstellungen'}
                                style={{
                                    backgroundColor: 'White',
                                    padding: '15px',
                                    marginTop: '-15px'
                                }}
                            >
                                {PielersForm(
                                    basesettings(
                                        element,
                                        i,
                                        newElement,
                                        newelementIndex,
                                        Store
                                    )
                                )}
                            </Tab>
                        </Tabs>
                    </div>
                    {newElement === false ? (
                        CopyElements(element.id, 'Text Icon', Store)
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default hexagonWebBuilder

const buildItems = (
    element: any,
    index: any,
    newElement: any,
    data: any,
    Store: any,
    galerie: any,
    galerieItems: any
) => {
    let Items = []
    let elementItem = element.items

    if (elementItem !== undefined) {
        for (let x = 0; x < elementItem.length; x++) {
            Items.push({
                formType: 'multiplyinRow',
                RowId: 'hexagonRow_' + x + '_' + index,
                singelCol: true,
                class: 'mb-3 boxShadowCard card hexacard',
                colsize: 4,
                cols: [
                    {
                        formType: 'input',
                        name: 'elements[' + index + '][items][' + x + '][id]',
                        type: 'hidden',
                        value: elementItem[x].id
                    },
                    {
                        formType: 'multiply',
                        cols: [
                            {
                                formType: 'formFile',
                                onclick: true,
                                id: 'media_titleFormField' + index + x,
                                onclick_context:
                                    newElement === false
                                        ? element.context
                                        : 'pielers_element_title_image',
                                Gallery: galerie,
                                galerieItems: galerieItems,
                                expandfile: index + 'galerie' + x,
                                imgupload: true,
                                experiment: true,
                                imgsrc:
                                    newElement === false
                                        ? elementItem[x].media !== null
                                            ? elementItem[x].media.cdn_url
                                            : defaultIMG()
                                        : defaultIMG(),
                                label: 'Bild',
                                width: 170,
                                height: 170,
                                append:
                                    'elements[' +
                                    index +
                                    '][items][' +
                                    x +
                                    '][media_id]',
                                src: 'media_title_image_' + index + x,
                                size: {
                                    xl: 6,
                                    md: 6,
                                    sm: 6
                                }
                            },
                            {
                                formType: 'formFile',
                                onclick: true,
                                id: 'media_hover_titleFormField' + index + x,
                                onclick_context:
                                    newElement === false
                                        ? element.context
                                        : 'pielers_element_title_image',
                                Gallery: galerie,
                                galerieItems: galerieItems,
                                expandfile: index + 'galerie' + x,
                                imgupload: true,
                                experiment: true,
                                imgsrc:
                                    newElement === false
                                        ? elementItem[x].media_hover !== null
                                            ? elementItem[x].media_hover.cdn_url
                                            : defaultIMG()
                                        : defaultIMG(),
                                label: 'Hover Bild',
                                width: 170,
                                height: 170,
                                append:
                                    'elements[' +
                                    index +
                                    '][items][' +
                                    x +
                                    '][media_hover_id]',
                                src: 'media_hover_title_image_' + index + x,
                                size: {
                                    xl: 6,
                                    md: 6,
                                    sm: 6
                                }
                            }
                        ]
                    },
                    {
                        formType: 'input',
                        name:
                            'elements[' +
                            index +
                            '][items][' +
                            x +
                            '][media_id]',
                        id:
                            'elements[' +
                            index +
                            '][items][' +
                            x +
                            '][media_id]',
                        visible: true,
                        label: 'mediaID',
                        type: 'hidden',
                        placeholder: 'mediaID',
                        value:
                            newElement === false ? elementItem[x].media_id : ''
                    },
                    {
                        formType: 'input',
                        name:
                            'elements[' +
                            index +
                            '][items][' +
                            x +
                            '][media_hover_id]',
                        id:
                            'elements[' +
                            index +
                            '][items][' +
                            x +
                            '][media_hover_id]',
                        visible: true,
                        label: 'media_hoverID',
                        type: 'hidden',
                        placeholder: 'media_hoverID',
                        value:
                            newElement === false
                                ? elementItem[x].media_hover_id
                                : ''
                    },
                    SunEditorBig(
                        newElement === false ? elementItem[x] : '',
                        'text',
                        newElement,
                        data,
                        'elements[' + index + '][items][' + x + '][text]',
                        IntlMessageValue('de', 'common.edit_text', Store),
                        '',
                        '',
                        '',
                        '',
                        'inline',
                        150
                    ),
                    {
                        formType: 'input',
                        label: (
                            <IntlMessage Store={Store} messageId="common.url" />
                        ),
                        type: 'text',
                        placeholder: IntlMessageValue(
                            'de',
                            'common.url',
                            Store
                        ),
                        name: 'elements[' + index + '][items][' + x + '][link]',
                        value: newElement === false ? elementItem[x].link : ''
                    },
                    {
                        formType: 'multiply',
                        cols: [
                            {
                                formType: 'select',
                                label: 'Animation',
                                name:
                                    'elements[' +
                                    index +
                                    '][items][' +
                                    x +
                                    '][animation]',
                                selected:
                                    newElement === false
                                        ? elementItem[x].animation
                                        : 1,
                                options: [
                                    {
                                        label: IntlMessageValue(
                                            'de',
                                            'common.activated',
                                            Store
                                        ),
                                        value: '1'
                                    },
                                    {
                                        label: IntlMessageValue(
                                            'de',
                                            'common.deactivated',
                                            Store
                                        ),
                                        value: '0'
                                    }
                                ]
                            },
                            {
                                formType: 'input',
                                label: (
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.css_class"
                                    />
                                ),
                                type: 'text',
                                placeholder: IntlMessageValue(
                                    'de',
                                    'common.css_class',
                                    Store
                                ),
                                name:
                                    'elements[' +
                                    index +
                                    '][items][' +
                                    x +
                                    '][class]',
                                value:
                                    newElement === false
                                        ? elementItem[x].class
                                        : ''
                            }
                        ]
                    },
                    {
                        formType: 'select',
                        label: 'Type',
                        name:
                            'elements[' +
                            index +
                            '][items][' +
                            x +
                            '][background]',
                        selected:
                            newElement === false
                                ? elementItem[x].background
                                : '',
                        options: [
                            {
                                label: '',
                                value: ''
                            },
                            {
                                label: 'background-cover',
                                value: 'background-cover'
                            },
                            {
                                label: 'background-contain',
                                value: 'background-contain'
                            },
                            {
                                label: 'background-contain flip-box-front-text',
                                value: 'background-contain flip-box-front-text'
                            }
                        ]
                    }
                ]
            })
        }
        let newConfiguration = Items
        return PielersForm(newConfiguration)
    } else {
        return
    }
}
