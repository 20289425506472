import React from 'react'
import PielersForm from '../../index'
import { base, basesettings } from './base'
import { CopyElements, IntlMessage, WebBuilderElementsHead } from '../../../Elements/index'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Box from '@mui/material/Box'

function rsWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any,
    overStart?: any
) {
    const index = newElement === false ? i : newelementIndex
    const configuration = [
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/rs.html'
        }
    ]

    let newConfiguration = configuration.concat(
        base(element, i, newElement, newelementIndex, overStart)
    )
    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Box
                id={'rs' + index}
                data-appendid={index}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
                style={{
                    borderTop: 'thick double rgb(56, 112, 247)',
                    borderBottom: 'thick double rgb(56, 112, 247)',
                    padding: '5px',
                    marginTop: ' 35px',
                    marginBottom: '40px'
                }}
            >
                {WebBuilderElementsHead(
                    <IntlMessage Store={Store} messageId="module.row_start" />,
                    'rs',
                    '',
                    Status,
                    index,
                    'elementButton',
                    'Text',
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}

                <Row
                    className={'boxShadowCard card bbunset'}
                    style={{
                        width: '100%',
                        background: 'transparent',
                        height: '1px'
                    }}
                >
                    <div id={'Teaser'}>Row End</div>

                    <Col>
                        <IntlMessage
                            Store={Store}
                            messageId="module.row_start"
                        />
                    </Col>
                    <Col className={'accordion-body element-body'}>
                        {PielersForm(newConfiguration)}
                    </Col>
                    <div
                        id={'settingstext' + index + 'rs'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store,
                                true
                            )
                        )}
                    </div>
                    {newElement === false ? (
                        CopyElements(
                            element.id,
                            <IntlMessage
                                messageId="module.row_start"
                                Store={Store}
                            />,
                            Store
                        )
                    ) : (
                        <></>
                    )}
                </Row>
                <header id="site-header" className="container-fluid px-0">
                    <div
                        id={'order' + index}
                        style={{ minHeight: '50px' }}
                        data-appenddiv={'true'}
                        data-appentclass={'col'}
                        data-injectEnd={true}
                        data-appendto={'order' + index}
                        className={
                            (element.class === '' ? 'row' : element.class) +
                            ' nested-sortable changepaddingRSElement'
                        }
                    ></div>
                </header>
                <div
                    id={'Injectcontainerend' + index}
                    style={{ display: 'none' }}
                ></div>
            </Box>
        </>
    )
}

export default rsWebBuilder
