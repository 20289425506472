import React from 'react'
import PielersForm from '../../index'
import { base, basesettings } from './base'
import { CopyElements, IntlMessage, WebBuilderElementsHead } from '../../../Elements/index'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Box from '@mui/material/Box'

function module_blogWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any
) {
    const index = newElement === false ? i : newelementIndex

    const configuration = [
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.headline" />,
            type: 'text',
            placeholder: 'lang[common.headline]',
            name: 'elements[' + index + '][translation][headline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.headline
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.topline" />,
            type: 'text',
            placeholder: 'lang[common.topline]',
            name: 'elements[' + index + '][translation][topline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.topline
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: 'Anzahl',
            type: 'number',
            name: 'elements[' + index + '][icon]',
            min: 3,
            value:
                newElement === false
                    ? element !== undefined
                        ? element.icon
                        : 3
                    : 3
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/blog_teaser.html'
        }
    ]

    let newConfiguration = configuration

    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Box
                id={'module_blog' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    <IntlMessage Store={Store} messageId="module.blog" />,
                    'module_blog',
                    newElement === false
                        ? element.translation !== undefined
                            ? element.translation.de.headline
                            : ''
                        : '',
                    Status,
                    index,
                    'elementButton',
                    <IntlMessage Store={Store} messageId="module.blog" />,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <div
                    id={'preview-container_module_blog' + index}
                    className={'preview-container ' + Status}
                    style={{ display: newElement === false ? 'block' : 'none' }}
                >
                    {newElement === false ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: element.previews
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    id={'mein_module_blog' + index}
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={'element-body'}>
                        <div
                            id={'edit_module_blog' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="module.module"
                                />
                            </Col>
                            <div id={'Teaser'}>image_title</div>
                            {PielersForm(
                                base(element, i, newElement, newelementIndex)
                            )}
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'module_blog'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store
                            )
                        )}
                        <h3>Modul</h3>
                        {PielersForm(newConfiguration)}
                    </div>
                    {newElement === false ? (
                        CopyElements(
                            element.id,
                            <IntlMessage
                                messageId="module.blog"
                                Store={Store}
                            />,
                            Store
                        )
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default module_blogWebBuilder
