import React, { useRef, useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import PielersForm from '../../../../@WUM/core/form-serilizer'
import { saveDataIndividualPut } from '../../../../@WUM/core/RestFullApi/ApiHook'
import { notify_save } from '../../../../@WUM/core/component/const/notification.service'
import { error, IntlMessage } from '../../../../@WUM/core/Elements/index'
import { handleSubmitForm } from '../../../../@WUM/core/Function/index'
import { btn_back } from '../../../../@WUM/core/component/const/btn_back'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ResponseCustomTypeBlueprint } from '../API/response/customTypes'
import configurationSingle from './configuration/Page/configurationSingle'

const CustomTypeNew = (value: any) => {
    const Store = useSelector((state: any) => state)
    const history = useNavigate()
    const [validated, setValidated] = useState(false)
    /*  const FormField = ResponseCustomTypesFormDetail(id,'?mode=1');
  const CustomType = ResponseCustomTypeDetail(id);*/
    ResponseCustomTypeBlueprint()
    const forms = useRef(null)
    const submit = () => {
        let Dataform = forms.current
        if (Dataform !== undefined && Dataform !== null) {
            const data = new FormData(Dataform)
            saveDataIndividualPut('/customTypeParent/Type/single/0/', data)
                .then((data: any) => {
                    notify_save(Store)
                    history(-1)
                })
                .catch((e) => {
                    error(e)
                })
        }
    }

    return (
        <div>
            <Form
                ref={forms}
                id={'form001'}
                noValidate
                validated={validated}
                onSubmit={(e) => handleSubmitForm(e, submit, setValidated, 0)}
            >
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={
                            <IntlMessage
                                Store={Store}
                                messageId="common.general"
                            />
                        }
                        key={'Allgemein'}
                        id={'Allgemein'}
                        style={{
                            backgroundColor: 'White',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {PielersForm(
                            configurationSingle('dataColOne', [], true, Store)
                        )}
                    </Tab>
                </Tabs>
                <div style={{ backgroundColor: 'white', padding: '15px' }}>
                    {btn_back(history, 'common.abort', '', Store)}
                    <Button
                        type={'submit'}
                        variant="success"
                        style={{ float: 'right', marginRight: '15px' }}
                    >
                        <IntlMessage Store={Store} messageId="common.save" />
                    </Button>
                    <br style={{ clear: 'both' }} />
                </div>
            </Form>
        </div>
    )
}

export default CustomTypeNew
