import React, { useState } from 'react'
import { Response, ResponseDelete } from '../../API/response/blog/blog'
import { SpinnerJSX } from '../../../../../@WUM/core/component/const/Spinner'
import configuration from '../configuration/Page/overview'
import { useNavigate, useParams } from 'react-router-dom'
import { removeUseLocalStorage } from '../../../../../@WUM/core/component/const/useLocalStoreage'
import { ContentSwitch, IntlMessage, IntlMessageValue } from '../../../../../@WUM/core/Elements/index'
import SearchJSX from '../../../../../@WUM/core/component/const/SearchJSX'
import { useSelector } from 'react-redux'
import '../../css/blog.css'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
/**
 * Give View Overview for Content Blog
 * @memberOf blog
 * @prop page
 * @prop deleteID
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const EmailOverview = (props: { value: any }) => {
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    const Store = useSelector((state: any) => state)
    removeUseLocalStorage('seo_title')
    removeUseLocalStorage('seo_description')

    const history = useNavigate()
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths: string) => {
        history(-1)
    }
    let limit: any
    if (deleteID) {
        ResponseDelete(deleteID)
        routeChange('/Email/overview/1')
    }

    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    }
    const destination = 'Email'
    const namespace_search = 'Email_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    const [search, setSearch] = useState<boolean>(false)
    const response = Response(limit, searchValue)
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    const Configuration = configuration('', response, page, Store)

    const [mode, setMode] = useState('cart')
    const debug = (search: boolean) => {}
    debug(search)

    let link
    if (process.env.REACT_APP_MODE === 'LIVE') {
        link = process.env.REACT_APP_PREVIEW_LINK_LIVE
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        link = process.env.REACT_APP_PREVIEW_LINK_DEVELOPMENT
    } else {
        link = process.env.REACT_APP_PREVIEW_LINK_LOCAL
    }

    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'common.search_for_title',
                    param: 'translation.title',
                    method: 'like'
                },
                {
                    id: 'search_title',
                    translation: 'common.date',
                    param: 'filter',
                    method: '=',
                    individual: (
                        <Form.Group
                            as={Col}
                            controlId={'search_title'}
                            name={'search_title'}
                        >
                            <Form.Label>Nach Titel Sortieren</Form.Label>
                            <Form.Select
                                aria-label="Filter"
                                defaultValue={
                                    localStorage.getItem(
                                        namespace_search + '_search_title'
                                    ) as string
                                }
                            >
                                <option />
                                <option value={'title'}>
                                    {IntlMessageValue(
                                        'de',
                                        'searchadded.title.up',
                                        Store
                                    )}
                                </option>
                                <option value={'-title'}>
                                    {IntlMessageValue(
                                        'de',
                                        'searchadded.title.down',
                                        Store
                                    )}
                                </option>
                            </Form.Select>{' '}
                        </Form.Group>
                    )
                }
            ],
            button: ['common.search', 'common.del.search', 'Neue Email anlegen']
        }
    ]

    const CardsConfiguration = {
        data: {
            ressource: response,
            indiBild: true,
            img: 'preview_media.cdn_url',
            status: 'visible',
            statusActive: (
                <IntlMessage Store={Store} messageId="common.activated" />
            ),
            statusDeactive: (
                <IntlMessage Store={Store} messageId="common.deactivated" />
            ),
            link: 'translation.de.slug',
            prelink: link + '/magazin/[param]/',
            prelinksearch: 'translation.de.slug',
            title: 'translation.de.title',
            description: 'published_at',
            router: 'id',
            preroute: '/Email/Detail/',
            edit: (
                <IntlMessage Store={Store} messageId={'blog.overview.button'} />
            ),
            delete: true,
            deleteLink: '/Email/Delete/' + page + '/[ID]'
        }
    }

    let NewPage = page === undefined ? 1 : page
    if (response.length > 0) {
        return (
            <>
                {' '}
                <SearchJSX
                    setSearchValue={setSearchValue}
                    Store={Store}
                    SearchConfig={SearchConfig}
                />
                {
                    <ContentSwitch
                        pagenew={'new'}
                        destinationnew={'Email'}
                        tonew={'new'}
                        text={
                            <IntlMessage
                                Store={Store}
                                locale={'de'}
                                messageId={'blog.new.post'}
                            />
                        }
                        response={response}
                        CardsConfiguration={CardsConfiguration}
                        mode={mode}
                        Configuration={Configuration}
                        setMode={setMode}
                        destination={destination}
                        page={NewPage}
                        to={'overview'}
                        setSearchValues={setSearchValue}
                        setSearchs={setSearch}
                        search={false}
                        modalShow={modalShow}
                        setModalShow={setModalShow}
                        modalData={modalData}
                        setModalData={setModalData}
                        SearchParam={'?translation.title'}
                        searchfield={false}
                        allTaxon={[]}
                        _handleKeyFilters={[]}
                        _handleEnter={[]}
                        filter={true}
                        stop={false}
                        Store={Store}
                    />
                }
            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default EmailOverview
