import { useEffect, useState } from 'react'
import { error } from '../../../../../../@WUM/core/Elements/index'
import { textBlocks } from '../../request/textBlocks/textBlocks'
import { textBlocksApiType } from '../../request/textBlocks/textBlocksAPI.interface'
import { checkLogin } from '../../../../../../@WUM/templates/default/Auth/service/AuthService'

export const Responsetextblocks = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<textBlocksApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await textBlocks
                .getPosts(limit, searchValue)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? isError : posts
}

export const ResponsetextblocksDetail = (id: string) => {
    const [posts, setPosts] = useState<textBlocksApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await textBlocks
                .getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}
