import { useEffect, useState } from 'react'
import { calenderApiType } from '../../request/calender/calenderApi.interface'
import { calender } from '../../request/calender/calender'
import { calenderApiDetail } from '../../request/calender/calenderApiDetail.interface'
import { error } from '../../../../../@WUM/core/Elements/index'

export const ResponseCalender = (limit: string, searchValue: any) => {
    const [posts, setPosts] = useState<calenderApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .getPosts(limit, searchValue)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

export const ResponseCalenderDetail = (id: any) => {
    const [posts, setPosts] = useState<calenderApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .getAPost(id)
                .then((data) => {
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}

export const ResponseCalenderDelete = (id: any) => {
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await calender
                .deletePost(id)
                .then((data) => {
                    return data
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : isError
}
