import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { useRef } from 'react'
import PielersForm from '../../../../../../../@WUM/core/form-serilizer'
import { saveDataIndividualPut } from '../../../../../../../@WUM/core/RestFullApi/ApiHook'
import { notify_saveProduct } from '../../../../../../../@WUM/core/component/const/notification.service'
import { error, IntlMessage, IntlMessageValue } from '../../../../../../../@WUM/core/Elements/index'
import { SunEditorConfig } from '../../../../../../../@WUM/core/component/const/SunEditorConfig'
import { memberApiType } from '../../../../API/request/member/memberApi.interface'
import { ResponseAllMerchant } from '../../../../API/response/member/member'
import SunEditorBig from '../../../../../../../@WUM/core/component/const/SunEditorBig'
import { useSelector } from 'react-redux'

export const setarcticle = (
    setModalarcticleShow: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void
) => {
    setModalarcticleShow(true)
}

const buildForm = (user: any, Store: any) => {
    if (
        localStorage.getItem('arcticle') !== null &&
        JSON.parse(localStorage.getItem('arcticle') as string)
            ?.Artikelkategorie !== null
    ) {
        let article: any = JSON.parse(
            localStorage.getItem('arcticle') as string
        )
        const debug = (AllMerchant: memberApiType[] | boolean) => {}
        const AllMerchant = ResponseAllMerchant()

        // user.merchantID !== null
        debug(AllMerchant)
        return [
            {
                formType: 'select',
                label: <IntlMessage Store={Store} messageId="common.status" />,
                name: 'status',
                selected: article.enabled,
                options: [
                    {
                        label: '',
                        value: ''
                    },
                    {
                        label: IntlMessageValue(
                            'de',
                            'sell_on.plattform',
                            Store
                        ),
                        value: '1'
                    },
                    {
                        label: IntlMessageValue(
                            'de',
                            'take_off.plattform',
                            Store
                        ),
                        value: '0'
                    }
                ]
            },
            {
                formType: 'input',
                label: <IntlMessage Store={Store} messageId="article.name" />,
                type: 'text',
                placeholder: IntlMessageValue(
                    'de',
                    'article.name.provide',
                    Store
                ),
                name: 'Artikelname',
                id: 'Artikelname',
                value: article.Artikelname
            },
            {
                formType: 'input',
                label: <IntlMessage Store={Store} messageId="article.no" />,
                type: 'text',
                placeholder: IntlMessageValue(
                    'de',
                    'article.no.provide',
                    Store
                ),
                name: 'Artikelnummer',
                id: 'Artikelnummer',
                value: article.Artikelnummer
            },
            {
                formType: 'fetchselect',
                local: 'MerchantAll',
                valueState: 'id',
                beginn: 'true',
                start: 0,
                type: 'MerchantAll',
                onClick_id: 'Roles',
                labelState: 'name',
                name: 'merchant_id',
                disabled: article.merchant_id !== null ? true : false,
                selected:
                    article.merchant_id !== null ? article.merchant_id : '',
                label: <IntlMessage Store={Store} messageId="role.provider" />
            },
            {
                formType: 'fetchselect',
                local: 'allTaxon',
                valueState: 'translatable_id',
                beginn: 'true',
                start: 0,
                labelState: 'name',
                selected:
                    article.Hauptkategorie[0] !== undefined
                        ? article.Hauptkategorie[0].id
                        : '',
                label: <IntlMessage Store={Store} messageId="category.main" />
            },
            {
                formType: 'fetchselect',
                local: 'allTaxon',
                valueState: 'translatable_id',
                beginn: 'true',
                start: 0,
                type: 'taxon',
                onClick_id: 'taxon',
                labelState: 'name',
                selected: '',
                label: (
                    <IntlMessage
                        Store={Store}
                        messageId="category.article.add"
                    />
                )
            },
            {
                formType: 'holder',
                ressource: article.Artikelkategorie,
                type: 'taxon'
            },

            {
                formType: 'checkbox',
                label: <IntlMessage Store={Store} messageId="is_grower" />,
                name: 'isGrower',
                visible: article.isGrower
            },
            SunEditorBig(
                article,
                'Kurzbeschreibung',
                false,
                { lang: 'de', height: '400px;', config: SunEditorConfig() },
                'Kurzbeschreibung',
                <IntlMessage
                    Store={Store}
                    messageId="common.short_description"
                />
            ),
            SunEditorBig(
                article,
                'Beschreibung',
                false,
                { lang: 'de', height: '400px;', config: SunEditorConfig() },
                'Beschreibung',
                <IntlMessage Store={Store} messageId="common.description" />
            )
        ]
    } else {
        return [
            {
                formType: 'input',
                label: <IntlMessage Store={Store} messageId="article.name" />,
                type: 'text',
                placeholder: IntlMessageValue(
                    'de',
                    'article.name.provide',
                    Store
                ),
                name: 'Artikelname',
                id: 'Artikelname',
                value: ''
            },
            {
                formType: 'input',
                label: <IntlMessage Store={Store} messageId="article.no" />,
                type: 'text',
                placeholder: IntlMessageValue(
                    'de',
                    'article.no.provide',
                    Store
                ),
                name: 'Artikelnummer',
                id: 'Artikelnummer',
                value: ''
            },
            {
                formType: 'fetchselect',
                local: 'allTaxon',
                valueState: 'translatable_id',
                beginn: 'true',
                start: 0,
                labelState: 'name',
                selected: '',
                label: <IntlMessage Store={Store} messageId="category.main" />
            },
            {
                formType: 'fetchselect',
                local: 'allTaxon',
                valueState: 'translatable_id',
                beginn: 'true',
                start: 0,
                type: 'taxon',
                onClick_id: 'taxon',
                labelState: 'name',
                selected: '',
                label: (
                    <IntlMessage
                        Store={Store}
                        messageId="category.article.add"
                    />
                )
            },
            {
                formType: 'holder',
                ressource: '',
                type: 'taxon'
            },

            {
                formType: 'checkbox',
                label: <IntlMessage Store={Store} messageId="is_grower" />,
                name: 'isGrower',
                visible: ''
            },
            SunEditorBig(
                false,
                'Kurzbeschreibung',
                true,
                { lang: 'de', height: '400px;', config: SunEditorConfig() },
                'Kurzbeschreibung',
                <IntlMessage
                    Store={Store}
                    messageId="common.short_description"
                />
            ),
            SunEditorBig(
                false,
                'Beschreibung',
                true,
                { lang: 'de', height: '400px;', config: SunEditorConfig() },
                'Beschreibung',
                <IntlMessage Store={Store} messageId="common.description" />
            )
        ]
    }
}

export const modalarcticle = (
    modalarcticleShow: boolean,
    setModalarcticleShow: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void,
    savearcticle: any,
    arcticleValue: any,
    id: any,
    saveattribute: any,
    user: any,
    Store: any
) => {
    let arcticle: any = arcticleValue

    return (
        <>
            <ArcticleModal
                show={modalarcticleShow}
                data={arcticle}
                fetchId={id}
                onSave={saveattribute}
                user={user}
                onHide={() => setModalarcticleShow(false)}
                savearcticle={savearcticle}
                Store={Store}
            />
        </>
    )
}

const ArcticleModal = (props: any) => {
    const Store = useSelector((state: any) => state)
    const forms = useRef(null)
    const submit = (e: { preventDefault: () => void }) => {
        e.preventDefault()
        let Dataform = forms.current
        if (Dataform !== undefined && Dataform !== null) {
            const data = new FormData(Dataform)
            //console.log(JSON.stringify(data as unknown as Array<any>))
            saveDataIndividualPut(
                '/Product/Hauptattribute/' + props.fetchId + '/',
                data
            )
                .then((data: any) => {
                    notify_saveProduct(Store)
                    //setTimeout(() => window.location.reload(), 3000);
                })
                .catch((e) => {
                    error(e)
                    //setTimeout(() => window.location.reload(), 3000);
                })
        }
        props.onSave(true)
        props.onHide()
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <form ref={forms} onSubmit={submit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <IntlMessage
                            Store={Store}
                            messageId="article.main.edit"
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'modalBody'}>
                        {PielersForm(buildForm(props.user, props.Store))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>
                        <IntlMessage Store={Store} messageId="common.close" />
                    </Button>
                    <Button
                        type={'submit'}
                        className={'float-end'}
                        variant="success"
                    >
                        <IntlMessage Store={Store} messageId="common.save" />
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
