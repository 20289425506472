import DynamikOverview from '../../dynamikModul/Modules/Pages/overview'
import React from 'react'
import {
    ResponseOverview,
    ResponseDelete,
    ResponseDetail
} from './API/response/Type/customizer'
import DynamikDeleted from '../../dynamikModul/Modules/Pages/deleted'
import DynamikDetail from '../../dynamikModul/Modules/Pages/detail'
import DynamikNew from '../../dynamikModul/Modules/Pages/new'
import CardJSX from './configuration/card'
import SearchConfig from './configuration/SearchConfig'
import { destination, link } from './helper/const'
import table from './configuration/table'
import Forms from './configuration/Form'
import Button from "react-bootstrap/Button";

export const BillPagesConfig = (value: any) => {


    return [
        {
            path: '/bill/overview/:page',
            elements: (
                <DynamikOverview
                    value={value}
                    table={table}
                    destination={destination}
                    state={'bill'}
                    SearchConfig={SearchConfig(destination)}
                    CardsConfiguration={CardJSX(link, destination)}
                    response={ResponseOverview}
                    dynamik={false}
                    buttonText={'Rechnung '}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/bill/Delete/:page/:deleteID',
            elements: (
                <DynamikDeleted
                    value={value}
                    reponse={ResponseDelete}
                    namespace={destination}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/bill/new/new',
            elements: (
                <DynamikNew
                    value={value}
                    forms={Forms}
                    namespace={'/' + destination + '/overview/1'}
                    saveRoute={'ewiv/invoices/0/'}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/bill/new/:page',
            elements: (
                <DynamikNew
                    value={value}
                    forms={Forms}
                    namespace={'/' + destination + '/overview/1'}
                    saveRoute={'ewiv/invoices/0/'}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/bill/Detail/:id',
            elements: (
                <DynamikDetail
                    value={value}
                    forms={Forms}
                    response={ResponseDetail}
                    saveRoute={'ewiv/invoices'}
                    ButtonTitle={'Erstellen'}
                    addedButton={  <p style={{textAlign:'center',float: 'left',width: '85%'}}><Button variant="primary" href={'[href]'}><span className="material-icons align-bottom">
file_download
            </span> Rechnung Herunterladen</Button></p>}
                    Buttonhref={['media','cdn_url']}
                />
            ),
            roles: ['superadmin', 'admin']
        }
    ]
}

export const BillMenuConfig = (value: any) => {
    return [
        {
            name: 'Rechnungen',
            route: '/bill/overview/1',
            icon: 'corporate_fare',
            roles: ['superadmin', 'admin']
        }
    ]
}

export const BillDasboard = () => [
    {
        title: 'Rechnungen',
        href: '/bill/overview/1',
        undertitle: 'Backoffice',
        icon: 'corporate_fare',
        text: 'EWIV',
        roles: ['superadmin', 'admin']
    }
]
