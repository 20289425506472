import React from 'react'
import PielersForm from '../../index'
import { base, basesettings } from './base'
import { CopyElements, IntlMessage, WebBuilderElementsHead } from '../../../Elements/index'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Box from '@mui/material/Box'

function reWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any,
    overStart?: any
) {
    const index = newElement === false ? i : newelementIndex
    const configuration = [
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/re.html'
        }
    ]

    let newConfiguration = configuration.concat(
        base(element, i, newElement, newelementIndex, overStart)
    )
    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Box
                id={'re' + index}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    <IntlMessage Store={Store} messageId="module.row_end" />,
                    're',
                    '',
                    Status,
                    index,
                    'elementButton',
                    're',
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}

                <Row
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        paddingBottom: '15px',
                        paddingTop: '15px',
                        width: '100%',
                        margin: '0px 0px 10px 0px'
                    }}
                >
                    <div id={'Teaser'}>Row Start</div>
                    <Col>
                        <IntlMessage Store={Store} messageId="module.row_end" />
                    </Col>
                    <Col className={'accordion-body element-body'}>
                        {PielersForm(newConfiguration)}
                    </Col>
                    <div
                        id={'settingstext' + index + 're'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store
                            )
                        )}
                    </div>
                    {newElement === false ? (
                        CopyElements(
                            element.id,
                            <IntlMessage
                                messageId="module.row_end"
                                Store={Store}
                            />,
                            Store
                        )
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default reWebBuilder
