import React from 'react'
import { Accordion, Tabs } from 'react-bootstrap'
import PielersForm from '../../index'
import Tab from 'react-bootstrap/Tab'
import { baseForms } from './base'
import { IntlMessage, IntlMessageValue, FormsHead, SunEditorBig } from '../../../Elements/index'

function ds(
    forms: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any
) {
    const index = newElement === false ? i : newelementIndex
    const configuration = [
        {
            formType: 'input',
            name: 'form[' + index + '][type]',
            type: 'hidden',
            value: forms.type
        },
        SunEditorBig(
            newElement === false ? forms : '',
            'text',
            newElement,
            forms,
            'form[' + index + '][text]',
            IntlMessageValue('de', 'common.edit_text', Store)
        ),
        {
            formType: 'select',
            label: 'Required',
            legende: IntlMessageValue(
                'de',
                'common.form.elements.select.required.legende',
                Store
            ),
            br: true,
            name: 'form[' + index + '][required]',
            selected: newElement === false ? forms.required : '',
            options: [
                {
                    label: IntlMessageValue('de', 'common.deactivated', Store),
                    value: ''
                },
                {
                    label: IntlMessageValue('de', 'common.activated', Store),
                    value: 'required'
                }
            ]
        }
    ]

    let newConfiguration = configuration
    let Status
    if (forms.visible === 1) {
        Status = 'elementActive'
    }
    if (forms.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Accordion.Item
                eventKey={index}
                id={'divider' + index}
                style={{ marginBottom: '10px' }}
            >
                {FormsHead(
                    IntlMessageValue('de', 'form.elements.select_start', Store),
                    'divider',
                    '',
                    Status,
                    index,
                    'elementButton',
                    'Text',
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    forms,
                    content,
                    setElements,
                    Store
                )}
                <Accordion.Body>
                    <Tabs
                        defaultActiveKey="Einstellungen"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        key={'tabindex'}
                    >
                        <Tab
                            eventKey="Einstellungen"
                            title={
                                <IntlMessage
                                    Store={Store}
                                    messageId="settings.settings.header"
                                    locale={'de'}
                                />
                            }
                            key={'Einstellungen'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            {PielersForm(newConfiguration)}
                            {PielersForm(
                                baseForms(
                                    forms,
                                    i,
                                    newElement,
                                    newelementIndex,
                                    Store
                                )
                            )}
                        </Tab>
                    </Tabs>
                </Accordion.Body>
            </Accordion.Item>
        </>
    )
}

export default ds
