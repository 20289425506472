import React from 'react'
import PielersForm from '../../index'
import { Button, Tabs } from 'react-bootstrap'
import { base, basesettings } from './base'
import Tab from 'react-bootstrap/Tab'
import {MergeTwoObjecttoArray} from '../../../Function/index'
import { CopyElements, IntlMessage, IntlMessageValue, SunEditorBig, WebBuilderElementsHead } from '../../../Elements/index'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ReactDOM from 'react-dom'
import Box from '@mui/material/Box'

function textwithIconWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any
) {
    const index = newElement === false ? i : newelementIndex
    const fake_headlines = [1, 2, 3, 4, 5, 6].map((n) => ({
        label: IntlMessageValue(
            'de',
            'module.fake_headline_n',
            Store,
            'replace',
            '[n]',
            n
        ),
        value: n
    }))

    const headlines = [1, 2, 3, 4, 5, 6].map((n) => ({
        label: IntlMessageValue(
            'de',
            'module.headline_n',
            Store,
            'replace',
            '[n]',
            n
        ),
        value: n + 6
    }))

    let options = MergeTwoObjecttoArray(fake_headlines, headlines)

    const configuration = [
        {
            formType: 'select',
            label: (
                <IntlMessage Store={Store} messageId="module.headline_type" />
            ),
            name: 'elements[' + index + '][headline_type]',
            legende: <IntlMessage Store={Store} messageId="common.status" />,
            selected: newElement === false ? element.headline_type : '',
            options: options
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.headline" />,
            name: 'elements[' + index + '][translation][headline]',
            type: 'text',
            placeholder: 'lang[common.insert_headline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.headline
                        : ''
                    : ''
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/text_icon.html'
        }
    ]

    const ctaButton = [
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.url" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.url', Store),
            name: 'elements[' + index + '][translation][url]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.url
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.button_text" />,
            type: 'text',
            max: 30,
            placeholder: IntlMessageValue('de', 'common.button_text', Store),
            name: 'elements[' + index + '][translation][cta_text]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.cta_text
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: (
                <IntlMessage Store={Store} messageId="common.button_title" />
            ),
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.button_title', Store),
            name: 'elements[' + index + '][translation][cta_title]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.cta_title
                        : ''
                    : ''
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="common.target" />,
            name: 'elements[' + index + '][translation][target]',
            selected:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.target
                        : ''
                    : '',
            options: [
                {
                    label: IntlMessageValue('de', 'common.standard', Store),
                    value: 1
                },
                {
                    label: IntlMessageValue('de', 'common.new_window', Store),
                    value: 2
                },
                {
                    label: IntlMessageValue(
                        'de',
                        'common.parent_window',
                        Store
                    ),
                    value: 3
                },
                {
                    label: IntlMessageValue('de', 'common.same_window', Store),
                    value: 4
                }
            ]
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.css_class" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.css_class', Store),
            name: 'elements[' + index + '][Class]',
            value: newElement === false ? element.Class : ''
        }
    ]

    let newConfiguration = configuration

    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }

    localStorage.setItem(
        'Iterator_text_icon_' + index,
        newElement === false
            ? element.hasOwnProperty('items')
                ? element.items.length
                : 0
            : 0
    )

    const newItemGenerate = (
        element: any,
        index: any,
        data: any,
        Store: any,
        galerie: any,
        galerieItems: any
    ) => {
        let Items = []
        let x
        if (
            localStorage.getItem('Iterator_text_icon_' + index) !== undefined &&
            localStorage.getItem('Iterator_text_icon_' + index) !== null
        ) {
            x = localStorage.getItem('Iterator_text_icon_' + index)
        } else {
            x = element.items.length
        }
        console.log(element.items[0].parent)
        Items.push({
            formType: 'multiplyinRow',
            RowId: 'hexagonRow_' + x + '_' + index,
            singelCol: true,
            nonCol: true,
            class: 'mb-3 boxShadowCard card hexacard',
            colsize: 6,
            cols: [
                {
                    formType: 'input',
                    label: 'Icon',
                    name: 'elements[' + index + '][items][' + x + '][icon]',
                    type: 'text',
                    placeholder: 'lang[common.insert_headline]',
                    value: ''
                },
                {
                    formType: 'input',
                    label: (
                        <IntlMessage
                            Store={Store}
                            messageId="common.headline"
                        />
                    ),
                    name:
                        'elements[' +
                        index +
                        '][items][' +
                        x +
                        '][iconHeadline]',
                    type: 'text',
                    placeholder: 'lang[common.insert_headline]',
                    value: ''
                },
                SunEditorBig(
                    '',
                    'iconHeadline',
                    true,
                    data,
                    'elements[' + index + '][items][' + x + '][text]',
                    IntlMessageValue('de', 'common.edit_text', Store),
                    '',
                    '',
                    '',
                    '',
                    'inline',
                    250
                ),
                {
                    formType: 'input',
                    name: 'elements[' + index + '][items][' + x + '][id]',
                    type: 'text',
                    value: ''
                },
                {
                    formType: 'input',
                    name: 'elements[' + index + '][items][' + x + '][parent]',
                    type: 'text',
                    value: element.items[0].parent
                }
            ]
        })

        let newConfiguration = Items
        let newElement = <>{PielersForm(newConfiguration)}</>
        let temp = document.createElement('div')
        temp.classList.add('col-md-6')
        ReactDOM.render(newElement, temp)
        let ele = document.getElementById(
            'text_iconRow' + index
        ) as HTMLDivElement
        if (ele !== null) {
            ele.appendChild(temp)
            // @ts-ignore

            if (
                localStorage.getItem('Iterator_text_icon_' + index) !==
                    undefined &&
                localStorage.getItem('Iterator_text_icon_' + index) !== null
            ) {
                let iterator =
                    parseInt(
                        String(
                            localStorage.getItem(
                                'Iterator_text_icon_' + index
                            ) as unknown as number
                        )
                    ) + 1
                localStorage.setItem(
                    'Iterator_text_icon_' + index,
                    String(iterator)
                )
            } else {
                localStorage.setItem('Iterator_text_icon_' + index, x + 1)
            }
        }
    }

    return (
        <>
            <Box
                id={'text_icon' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    'Text Icon',
                    'text_icon',
                    newElement === false
                        ? element.translation !== undefined
                            ? element.translation.de.headline
                            : ''
                        : '',
                    Status,
                    index,
                    'elementButton',
                    'Text Icon',
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <div
                    id={'preview-container_text_icon' + index}
                    className={'preview-container ' + Status}
                    style={{ display: newElement === false ? 'block' : 'none' }}
                >
                    {newElement === false ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: element.previews
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    id={'mein_text_icon' + index}
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={'element-body'}>
                        <div
                            id={'edit_text_icon' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <div id={'Teaser'}>text</div>
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                Text Icon
                            </Col>
                            <Button
                                variant={'success'}
                                onClick={() =>
                                    newItemGenerate(
                                        element,
                                        index,
                                        data,
                                        Store,
                                        '',
                                        ''
                                    )
                                }
                                className={'mb-3 '}
                            >
                                Neues Text Icon hinzufügen
                            </Button>
                            <br />
                            <Row id={'text_iconRow' + index}>
                                {buildItems(
                                    element,
                                    index,
                                    newElement,
                                    data,
                                    Store,
                                    '',
                                    ''
                                )}
                            </Row>
                            {PielersForm(
                                base(element, i, newElement, newelementIndex)
                            )}
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'text_icon'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            key={'tabindex'}
                        >
                            <Tab
                                eventKey="home"
                                title={
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.general"
                                    />
                                }
                                key={'allgemein'}
                                id={'allgemein'}
                                style={{
                                    backgroundColor: 'White',
                                    padding: '15px',
                                    marginTop: '-15px'
                                }}
                            >
                                {PielersForm(newConfiguration)}
                            </Tab>
                            <Tab
                                eventKey="cta"
                                title={'CTA Button'}
                                key={'cta'}
                                id={'cta'}
                                style={{
                                    backgroundColor: 'White',
                                    padding: '15px',
                                    marginTop: '-15px'
                                }}
                            >
                                {PielersForm(ctaButton)}
                            </Tab>
                            <Tab
                                eventKey="Einstellungen"
                                title={
                                    <IntlMessage
                                        Store={Store}
                                        messageId="settings.settings.header"
                                        locale={'de'}
                                    />
                                }
                                key={'Einstellungen'}
                                style={{
                                    backgroundColor: 'White',
                                    padding: '15px',
                                    marginTop: '-15px'
                                }}
                            >
                                {PielersForm(
                                    basesettings(
                                        element,
                                        i,
                                        newElement,
                                        newelementIndex,
                                        Store
                                    )
                                )}
                            </Tab>
                        </Tabs>
                    </div>
                    {newElement === false ? (
                        CopyElements(element.id, 'Text Icon', Store)
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default textwithIconWebBuilder

const buildItems = (
    element: any,
    index: any,
    newElement: any,
    data: any,
    Store: any,
    galerie: any,
    galerieItems: any
) => {
    let Items = []
    let elementItem = element.items

    if (elementItem !== undefined) {
        for (let x = 0; x < elementItem.length; x++) {
            Items.push({
                formType: 'multiplyinRow',
                RowId: 'hexagonRow_' + x + '_' + index,
                singelCol: true,
                class: 'mb-3 boxShadowCard card hexacard',
                colsize: 6,
                cols: [
                    {
                        formType: 'input',
                        label: 'Icon',
                        name: 'elements[' + index + '][items][' + x + '][icon]',
                        type: 'text',
                        placeholder: 'lang[common.insert_headline]',
                        value: newElement === false ? elementItem[x].icon : ''
                    },
                    {
                        formType: 'input',
                        label: (
                            <IntlMessage
                                Store={Store}
                                messageId="common.headline"
                            />
                        ),
                        name:
                            'elements[' +
                            index +
                            '][items][' +
                            x +
                            '][iconHeadline]',
                        type: 'text',
                        placeholder: 'lang[common.insert_headline]',
                        value:
                            newElement === false
                                ? elementItem[x].iconHeadline
                                : ''
                    },
                    SunEditorBig(
                        newElement === false ? elementItem[x] : '',
                        'text',
                        newElement,
                        data,
                        'elements[' + index + '][items][' + x + '][text]',
                        IntlMessageValue('de', 'common.edit_text', Store),
                        '',
                        '',
                        '',
                        '',
                        'inline',
                        250
                    ),
                    {
                        formType: 'input',
                        name: 'elements[' + index + '][items][' + x + '][id]',
                        type: 'text',
                        value: newElement === false ? elementItem[x].id : ''
                    },
                    {
                        formType: 'input',
                        name:
                            'elements[' + index + '][items][' + x + '][parent]',
                        type: 'text',
                        value: newElement === false ? elementItem[x].parent : ''
                    }
                ]
            })
        }
        let newConfiguration = Items
        return PielersForm(newConfiguration)
    } else {
        return
    }
}
