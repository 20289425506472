import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import home from './elements/home'
import { init2 } from '../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from './configuration/mapping'
import { notify_save } from '../../../../@WUM/core/component/const/notification.service'
import { error, IntlMessage } from '../../../../@WUM/core/Elements/index'
import { btn_back } from '../../../../@WUM/core/component/const/btn_back'
import { handleSubmitForm } from '../../../../@WUM/core/Function/index'
import { Form } from 'react-bootstrap'
import {
    ResponseCalenderALLSubscripeDetail,
    ResponseCalenderDetail
} from '../API/response/calender/calender'
import { calenderApiDetail } from '../API/request/calender/calenderApiDetail.interface'
import { calender } from '../API/request/calender/calender'
import { useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import { saveDataIndividualPut } from '../../../../@WUM/core/RestFullApi/ApiHook'

const PageDetail = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const { id } = useParams<{ id: string }>()
    const response2 = ResponseCalenderDetail(id)
    const Subcripte = ResponseCalenderALLSubscripeDetail(id)
    const history = useNavigate()

    const [currentTutorial, setCurrentTutorial] =
        useState<calenderApiDetail[]>(response2)

    const [validated, setValidated] = useState(false)

    const setState = (stateValue: any, subscribeID: any) => {
        const data = { state: stateValue }
        saveDataIndividualPut(
            '/eventsEntrys/eventSubcriber/State/' + subscribeID + '/',
            data
        )
            .then((data: any) => {
                notify_save(Store)
                setTimeout(() => window.location.reload(), 1000)
            })
            .catch((e) => {
                error(e)
            })
    }

    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     * @param form
     */
    const updateData = (status: boolean, id: any, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        let reauestData: calenderApiDetail[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        calender
            .updatePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                /* notify_save_intvall_reload();*/
                $('#btn_save')[0].setAttribute('disabled', 'disbaled')
                setTimeout(() => window.location.reload(), 3000)
            })
            .catch((e) => {
                error(e)
            })
    }
    const buildListGroup = (Subcripte: any) => {
        let ListHolder = []

        for (let s = 0; s < Subcripte.length; s++) {
            ListHolder.push(
                <>
                    <ListGroup.Item>
                        Name: {Subcripte[s].user} | Firma:{' '}
                        {Subcripte[s].company} | Webseite:{' '}
                        {Subcripte[s].website} | Emial: {Subcripte[s].mail} |
                        Status: {State(Subcripte[s].state)} |{' '}
                        {Subcripte[s].state === '2' ? (
                            <>
                                <Button
                                    variant="success"
                                    onClick={() => setState(1, Subcripte[s].id)}
                                >
                                    Zusagen
                                </Button>{' '}
                                <Button
                                    variant="danger"
                                    onClick={() => setState(0, Subcripte[s].id)}
                                >
                                    Absagen
                                </Button>
                            </>
                        ) : (
                            <></>
                        )}
                    </ListGroup.Item>
                </>
            )
        }

        return (
            <>
                <ListGroup>{ListHolder}</ListGroup>
            </>
        )
    }

    const State = (state: any) => {
        switch (state) {
            case '0':
                return 'Abgesagt'
            case '1':
                return 'Zugesagt'
            case '2':
                return 'Wartend'
        }
    }
    return (
        <div>
            <Form
                id={'form001'}
                className={'eventsCalender'}
                noValidate
                validated={validated}
                onSubmit={(e) =>
                    handleSubmitForm(e, updateData, setValidated, id)
                }
            >
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="home"
                        title={
                            <IntlMessage
                                messageId="common.general"
                                Store={Store}
                            />
                        }
                        key={'allgemein'}
                        id={'allgemein'}
                        style={{
                            backgroundColor: 'White',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {home(response2, Store)}
                    </Tab>
                    <Tab
                        eventKey="subscribe"
                        title={'Anfragen'}
                        key={'subscribe'}
                        id={'subscribe'}
                        style={{
                            backgroundColor: 'White',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        {buildListGroup(Subcripte)}
                    </Tab>
                </Tabs>
                <div style={{ backgroundColor: 'white', padding: '15px' }}>
                    {btn_back(history, 'common.abort', '', Store)}
                    <Button
                        variant="success"
                        id={'btn_save'}
                        style={{ float: 'right', marginRight: '15px' }}
                        type={'submit'}
                    >
                        <IntlMessage messageId="common.save" Store={Store} />
                    </Button>
                    <br style={{ clear: 'both' }} />
                </div>
            </Form>
        </div>
    )
}

export default PageDetail
