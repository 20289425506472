import React from 'react'
import PielersForm from '../../index'
import { base, basesettings } from './base'
import {MergeTwoObjecttoArray} from '../../../Function/index'
import { CopyElements, IntlMessage, IntlMessageValue, WebBuilderElementsHead } from '../../../Elements/index'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Box from '@mui/material/Box'

function headlineWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any
) {
    const index = newElement === false ? i : newelementIndex

    const fake_headlines = [1, 2, 3, 4, 5, 6].map((n) => ({
        label: IntlMessageValue(
            'de',
            'module.fake_headline_n',
            Store,
            'replace',
            '[n]',
            n
        ),
        value: n
    }))

    const headlines = [1, 2, 3, 4, 5, 6].map((n) => ({
        label: IntlMessageValue(
            'de',
            'module.headline_n',
            Store,
            'replace',
            '[n]',
            n
        ),
        value: n + 6
    }))

    let options = MergeTwoObjecttoArray(fake_headlines, headlines)

    const configuration = [
        {
            formType: 'select',
            label: (
                <IntlMessage Store={Store} messageId="module.headline_type" />
            ),
            name: 'elements[' + index + '][headline_type]',
            legende: <IntlMessage Store={Store} messageId="common.status" />,
            selected: newElement === false ? element.headline_type : '',
            options: options
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.headline" />,
            name: 'elements[' + index + '][translation][text]',
            type: 'text',
            placeholder: 'lang[common.insert_headline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.text
                        : ''
                    : ''
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/hl.html'
        }
    ]

    let newConfiguration = configuration.concat(
        base(element, i, newElement, newelementIndex)
    )

    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }

    return (
        <>
            <Box
                id={'headline' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                style={{ border: '4px solid transparent' }}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    '',
                    'headline',
                    newElement === false
                        ? element.translation !== undefined
                            ? element.translation.de.text
                            : ''
                        : '',
                    Status,
                    index,
                    'elementButton',
                    <IntlMessage
                        Store={Store}
                        messageId="content.element.headline.type"
                    />,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <div
                    id={'preview-container_headline' + index}
                    className={'preview-container ' + Status}
                    style={{ display: newElement === false ? 'block' : 'none' }}
                >
                    {newElement === false ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: element.previews
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    id={'mein_headline' + index}
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={' element-body'}>
                        <div
                            id={'edit_headline' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="module.headline"
                                    locale={'de'}
                                />
                            </Col>
                            <div id={'Teaser'}>headline</div>
                            {PielersForm(newConfiguration)}
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'headline'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store
                            )
                        )}
                    </div>
                    {newElement === false ? (
                        CopyElements(
                            element.id,
                            <IntlMessage
                                messageId="module.headline"
                                Store={Store}
                            />,
                            Store
                        )
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default headlineWebBuilder
