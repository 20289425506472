import React, {useState} from 'react'
import { ResponseMerchant } from '../../../API/response/orders/orders'
import PielersTable from '../../../../../../@WUM/core/tablemaker'
import { SpinnerJSX } from '../../../../../../@WUM/core/component/const/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { handleSucces } from '../../../../../../@WUM/core/Function/index'
import PaginationJSX from '../../../../../../@WUM/core/form-serilizer/elements/pagination'
import {IntlMessage, IntlMessageValue} from '../../../../../../@WUM/core/Elements/index'
import { DummyUser } from '../../../../../../@WUM/core/dummy/user'
import { useSelector } from 'react-redux'
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import SearchJSX from "../../../../../../@WUM/core/component/const/SearchJSX";

interface NotificationsProps {
    value: any
}

const OrdersMerchantoverview: React.FC<NotificationsProps> = (value: any) => {
    const user = DummyUser()

    const Store = useSelector((state: any) => state)
    const forbidden = (search: any) => {
        if (user !== null) {
            for (let u = 0; u < user.role.length; u++) {
                if (user.role[u] === search) {
                    return true
                } else {
                }
            }
        }
    }
    const { page } = useParams<{ page: string }>()
    let limit: any
    let confimID
    if (
        user !== null &&
        user !== undefined &&
        (forbidden('merchant') === true || forbidden('merchant_ma') === true)
    ) {
        if (user.merchant_id !== null) {
            confimID = user.merchant_id
        } else {
            confimID = '0'
        }
    } else {
        confimID = '0'
    }
    if (typeof page === 'string') {
        limit = (parseFloat(page) - 1) * 10
    }


    const history = useNavigate()
    const destination = 'Orders/Merchant'
    const namespace_search = 'OrdersMerchant_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    const response = ResponseMerchant(confimID, limit,searchValue)
    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: [
                'checkout_completed_at',
                'number',
                'state',
                'shipping_state',
                'paymentDetail.name',
                'payment_state',
                'invoiceNumber',
                'customer.name',
                'items'
            ],
            type: [
                'dateCut',
                'string',
                'orderstate',
                'shipping_state',
                'string',
                'payment_state',
                'addedString|',
                'costumer',
                'summeWithStorno'
            ]
        },
        tableHeader: [
            <IntlMessage Store={Store} messageId="order.date" />,
            <IntlMessage Store={Store} messageId="order.no" />,
            <IntlMessage Store={Store} messageId="common.status" />,
            <IntlMessage Store={Store} messageId="delivery.status" />,
            <IntlMessage Store={Store} messageId="payment.method" />,
            <IntlMessage Store={Store} messageId="payment.status" />,
            <IntlMessage Store={Store} messageId="invoice.no" />,
            <IntlMessage Store={Store} messageId="common.customer" />,
            <IntlMessage Store={Store} messageId="common.price.total" />
        ],
        tableHeaderWidth: [
            '10%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%',
            '20%',
            '10%'
        ],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menuItem: [<IntlMessage Store={Store} messageId="display.order" />],
            menuroute: ['/Orders/Merchant/Detail'],
            menuParams: [false, 'id', 'id'],
            menuIcons: ['eye']
        }
    }

    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'common.customer',
                    param: 'customer.name',
                    method: 'like'
                },
                {
                    id: 'search_ordernumber',
                    translation: 'Bestellnummer',
                    param: 'number',
                    method: 'like'
                },
               /* {
                    id: 'search_invoiceNumber',
                    translation: 'Rechnungsnummer',
                    param: 'invoiceNumber',
                    method: 'like'
                },*/
                {
                    id: 'search_state',
                    translation: 'Status',
                    param: 'state',
                    method: 'like',
                    individual: (
                        <Form.Group
                            as={Col}
                            controlId={'search_state'}
                            name={'search_title'}
                        >
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                                aria-label="Filter"
                                defaultValue={
                                    localStorage.getItem(
                                        namespace_search + '_search_state'
                                    ) as string
                                }
                            >
                                <option />
                                <option value={'new'}>
                                    {IntlMessageValue(
                                        'de',
                                        'common.new_order',
                                        Store
                                    )}
                                </option>
                                <option value={'addressed'}>
                                    {IntlMessageValue(
                                        'de',
                                        'common.completed',
                                        Store
                                    )}
                                </option>
                            </Form.Select>{' '}
                        </Form.Group>
                    )
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'blog.new.post',
                'common.create_new_taxon'
            ]
        }
    ]

    if (response.length > 0) {
        return (
            <>
                <div className={'site'}>
                    <SearchJSX
                        noNewButton={true}
                        setSearchValue={setSearchValue}
                        Store={Store}
                        SearchConfig={SearchConfig}
                    />
                    <PielersTable Configuration={Configuration} />
                    <PaginationJSX
                        response={response}
                        history={history}
                        to={'overview'}
                        handleSuccess={handleSucces}
                        destination={destination}
                    />
                </div>
            </>
        )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default OrdersMerchantoverview
/*function show () {

  const spinnerElement = document.getElementsByClassName('spinner');
  const siteElement = document.getElementsByClassName('site');
  spinnerElement[0].setAttribute('style','display:none');
  siteElement[0].setAttribute('style','display:block');
}*/
