import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import home from '../../../../../@WUM/core/component/core/elements/home'
import Content from '../../../../../@WUM/core/component/core/elements/content'
import { blogApiDetail } from '../../API/request/blog/blogApiDetail.interface'
import { init2 } from '../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { configBlog, outputBlog } from '../configuration/mapping'
import { email } from '../../API/request/blog/blog'
import { notify_save } from '../../../../../@WUM/core/component/const/notification.service'
import { error } from '../../../../../@WUM/core/Elements/index'
import { Form } from 'react-bootstrap'
import { handleSubmitForm } from '../../../../../@WUM/core/Function/index'
import {IntlMessage} from '../../../../../@WUM/core/Elements/index'
import { useSelector } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

/**
 * Give View NewBlog for Content Blog
 * @memberOf blog
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const EmailNew = (props: { value: any }) => {
    //const user = 1;
    const history = useNavigate()

    const Store = useSelector((state: any) => state)

    const [currentTutorial, setCurrentTutorial] = useState<blogApiDetail[]>([])
    const [element, setElement] = useState([])
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])

    const [validated, setValidated] = useState(false)
    const [Index, setIndex] = React.useState(0)
    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     */
    const updateData = (status: boolean, id: any, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        let reauestData: blogApiDetail[] & configBlog = Object.assign(
            {},
            currentTutorial,
            outputBlog(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        email
            .updatePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save(Store)
                setTimeout(() => (window.location.href = '/Email/1'), 3000)
            })
            .catch((e) => {
                error(e)
            })
    }

    return (
        <div>
            <Form
                id={'form001'}
                className={'formControl'}
                noValidate
                validated={validated}
                onSubmit={(e) =>
                    handleSubmitForm(e, updateData, setValidated, 0)
                }
            >
                <Row>
                    <Col
                        style={{
                            display: 'block',
                            minWidth: '260px',
                            maxWidth: '260px'
                        }}
                    >
                        <div className={'settingsContent'}>
                            <div id={'home'} className={'pl-3 pr-3'}>
                                {home({ response: 'new', Store: Store })}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        {Content({
                            response: 'new',
                            elements: element,
                            setElements: setElement,
                            modalShow: modalShow,
                            setModalShow: setModalShow,
                            modalData: modalData,
                            setModalData: setModalData,
                            setdeleteElement: false,
                            Index: Index,
                            setIndex: setIndex,
                            Store: Store,
                            mode: 'email'
                        })}
                    </Col>
                </Row>
                <Row className={'ContentButton'}>
                    <Row className={'m-0'} style={{ height: '100%' }}>
                        <Col className={'mt-2 text-center'}>
                            <Button
                                variant="light"
                                style={{ float: 'left', marginLeft: '15px' }}
                                onClick={() => history(-1)}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.back"
                                />
                            </Button>
                        </Col>
                        <Col className={'mt-2  text-center'}>
                            <Button
                                variant="success"
                                style={{ float: 'right', marginRight: '15px' }}
                                type={'submit'}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.save"
                                />
                            </Button>
                        </Col>
                    </Row>
                </Row>

                <div style={{ backgroundColor: 'white', padding: '15px' }}>
                    <br style={{ clear: 'both' }} />
                </div>
            </Form>
        </div>
        /* <div>
      <Form  id={'form001'} className={'formControl'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, updateData, setValidated, 0)}>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
          <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
            {home({response:'new',Store:Store})}
          </Tab>
          <Tab eventKey="content" title={<IntlMessage Store={Store} messageId="common.content" />} key={'Inhalt'} id={'Inhalt'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
            {Content({response:'new', elements:element, setElements:setElement, modalShow:modalShow,
              setModalShow:setModalShow, modalData:modalData, setModalData:setModalData,setdeleteElement:false,Index:Index,setIndex:setIndex,Store:Store,mode:'email'})}
          </Tab>
        </Tabs>
        <div style={{backgroundColor: 'white', padding: '15px'}}>
          <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
          <Button variant='success'
            style={{float: 'right', marginRight: '15px'}}
            type={'submit'}
          >
            {<IntlMessage Store={Store} messageId="common.save" />}
          </Button>
          <br style={{clear: 'both'}} />
        </div>
      </Form>
    </div>*/
    )
}

export default EmailNew
