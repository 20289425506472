import React from 'react'
import PielersForm from '../../form-serilizer'
import Button from 'react-bootstrap/Button'
import './copyElements.css'
import { saveDataIndividualPut } from '../../RestFullApi/ApiHook'
import { error } from './../../Elements/index'
import { notify_copy_Element_error } from './../../Function/index'

const CopyElements = (id: any, Element: any, store: any) => {
    const configuration = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'Elementid',
            value: id
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'fetchselect',
                    local: 'fetchSelectBlog',
                    valueState: 'id',
                    groupDisbled: ['Site' + id],
                    name: 'Blog' + id,
                    store: true,
                    contentStore: store,
                    storeEntry: 'FETCHSELECTBlog',
                    storePoint: 'Blog',
                    onClickType: 'true',
                    beginn: 'true',
                    start: 0,
                    labelState: 'title',
                    label: 'Blogs'
                },
                {
                    formType: 'fetchselect',
                    local: 'fetchSelect',
                    valueState: 'id',
                    groupDisbled: ['Blog' + id],
                    name: 'Site' + id,
                    onClickType: 'true',
                    store: true,
                    contentStore: store,
                    storeEntry: 'FETCHSELECTSite',
                    storePoint: 'Site',
                    beginn: 'true',
                    start: 0,
                    labelState: 'title',
                    label: 'Site'
                }
            ]
        }
    ]

    const closeElements = () => {
        let modal = document.getElementById(
            'ModalCopyElements' + id
        ) as HTMLDivElement
        modal.style.display = 'none'
    }

    const FetchElement = () => {
        let groupDisbled = ['Site' + id, 'Blog' + id]
        let typeID = null
        for (let x = 0; x < groupDisbled.length; x++) {
            let element = document.getElementsByName(
                groupDisbled[x]
            )[0] as HTMLSelectElement
            if (element.value !== '0') {
                typeID = element.value
            }
        }
        if (typeID !== null) {
            saveDataIndividualPut(
                '/elements/copy/' + id + '/' + typeID + '/',
                ''
            )
                .then((data: any) => {
                    let modal = document.getElementById(
                        'ModalCopyElements' + id
                    ) as HTMLDivElement
                    modal.style.display = 'none'
                })
                .catch((e: any) => {
                    error(e)
                })
        } else {
            notify_copy_Element_error(store)
        }
    }

    return (
        <>
            <div id={'ModalCopyElements' + id} className="modal modal-copy">
                <div className="modal-content modal-content-copy">
                    <div>
                        <span
                            className="close-copy float-end"
                            onClick={() => closeElements()}
                        >
                            &times;
                        </span>
                    </div>
                    <div
                        className={'MainBackground'}
                        style={{ backgroundColor: 'white', padding: '15px' }}
                        key={'button'}
                    >
                        <h3>{Element}</h3>
                        {PielersForm(configuration)}
                        <Button
                            variant={'success'}
                            onClick={() => FetchElement()}
                        >
                            Element Kopieren
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CopyElements
