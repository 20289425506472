import search_result_merchant from './pageElements/search_result_merchant'
import textWebBuilder from '../modulWebBuilder/pageElements/text'
import rsWebBuilder from '../modulWebBuilder/pageElements/rs'
import reWebBuilder from '../modulWebBuilder/pageElements/re'
import image_titleWebBuilder from '../modulWebBuilder/pageElements/image_title'
import imageWebBuilder from '../modulWebBuilder/pageElements/image'
import videoWebBuilder from '../modulWebBuilder/pageElements/video'
import headlineWebBuilder from '../modulWebBuilder/pageElements/headline'
import anchorWebBuilder from '../modulWebBuilder/pageElements/anchor'
import search_resultWebBuilder from '../modulWebBuilder/pageElements/search_result'
import module_text_imageWebBuilder from '../modulWebBuilder/pageElements/module_text_image'
import module_customer_stepsWebBuilder from '../modulWebBuilder/pageElements/module_customer_steps'
import dividerWebBuilder from '../modulWebBuilder/pageElements/divider'
import carouselItem_endWebBuilder from '../modulWebBuilder/pageElements/carouselItem_end'
import carouselItem_StartWebBuilder from '../modulWebBuilder/pageElements/carouselItem_start'
import slider_startWebBuilder from '../modulWebBuilder/pageElements/slider_start'
import slider_endWebBuilder from '../modulWebBuilder/pageElements/slider_end'
import text_centerWebBuilder from '../modulWebBuilder/pageElements/text_center'
import sliderWebBuilder from '../modulWebBuilder/pageElements/slider'
import accordionWebBuilder from '../modulWebBuilder/pageElements/accordion'
import formularWebBuilder from '../modulWebBuilder/pageElements/formular'
import SitemapWebBuilder from '../modulWebBuilder/pageElements/sitemap'
import magazinWebBuilder from '../modulWebBuilder/pageElements/magazin'
import module_blogWebBuilder from '../modulWebBuilder/pageElements/module_blog'
import module_duoWebBuilder from '../modulWebBuilder/pageElements/module_duo'
import module_contentWebBuilder from '../modulWebBuilder/pageElements/module_content'
import module_titleWebBuilder from '../modulWebBuilder/pageElements/module_title'
import calenderWebBuilder from '../modulWebBuilder/pageElements/calender'
import text_teaserWebBuilder from '../modulWebBuilder/pageElements/text_teaser'
import module_searchformWebBuilder from '../modulWebBuilder/pageElements/module_searchform'
import module_video_duoWebBuilder from '../modulWebBuilder/pageElements/module_video_duo'
import module_social_mediaWebBuilder from '../modulWebBuilder/pageElements/module_social_media'
import ctaWebBuilder from '../modulWebBuilder/pageElements/cta'
import text_imageWebBuilder from '../modulWebBuilder/pageElements/text_image'
import galleryWebBuilder from '../modulWebBuilder/pageElements/gallery'
import text_blockerWebBuilder from '../modulWebBuilder/pageElements/text_blocker'
import htmlWebBuilder from '../modulWebBuilder/pageElements/html'
import textwithIconWebBuilder from '../modulWebBuilder/pageElements/text_icon'
import mapWebBuilder from '../modulWebBuilder/pageElements/map'
import hexagonWebBuilder from '../modulWebBuilder/pageElements/hexagon'
import ung_masonryWebBuilder from '../modulWebBuilder/pageElements/ung_masonry'
import cardWebBuilder from '../modulWebBuilder/pageElements/card'
import containerWebBuilder from '../modulWebBuilder/pageElements/cointainer'
import containerendWebBuilder from '../modulWebBuilder/pageElements/container_end'
import menuWebBuilder from '../modulWebBuilder/pageElements/menu'
import React from 'react'
function renderSwitch(
    data: { elements: { [x: string]: any }; conf: { [x: string]: any } },
    i: number,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    setdeleteElement: any = false,
    setElements: any = [],
    Store: any,
    galerie?: any,
    galerieItems?: any,
    col?: any,
    row?: any
) {
    if (data.elements[i].type === 'search_result_products') {
        data.elements[i].type = 'search_result'
        data.elements[i].search_entity = 'products'
    } else if (data.elements[i].type === 'search_result_merchant') {
        data.elements[i].type = 'search_result'
        data.elements[i].search_entity = 'merchants'
    } else if (data.elements[i].type === 'text_center') {
        data.elements[i].type = 'text'
    }

    if (newElement === true) {
        console.log(data.elements)
    }

    switch (data.elements[i].type) {
        case 'image_title':
            return image_titleWebBuilder(
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                galerie,
                galerieItems
            )
        case 'text_center':
            return text_centerWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'text':
            return textWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'menu':
            return menuWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'calender':
            return calenderWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'slider':
            return sliderWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'accordion':
            return accordionWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'text_icon':
            return textwithIconWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'anchor':
            return anchorWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'formular':
            return formularWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'html':
            return htmlWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'text_blocker':
            return text_blockerWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'divider':
            return dividerWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'sitemap':
            return SitemapWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'magazin':
            return magazinWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'container':
            if (newElement === true) {
                return (
                    <>
                        {containerWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store,
                            'container',
                            col,
                            row,
                            containerendWebBuilder(
                                data.conf[i],
                                data.elements[i],
                                i,
                                true,
                                newelementIndex,
                                modalShow,
                                setModalShow,
                                modalData,
                                setModalData,
                                setdeleteElement,
                                setElements,
                                Store,
                                'container_end',
                                col,
                                row
                            )
                        )}
                    </>
                )
            } else {
                return (
                    <>
                        {containerWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store,
                            'container',
                            col,
                            row
                        )}
                    </>
                )
            }
        case 'container_end':
            return containerendWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                'container_end',
                col,
                row
            )
        case 'row_start':
            if (newElement === true) {
                return (
                    <>
                        {rsWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store,
                            'row_start'
                        )}
                        {reWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex + 1,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store,
                            'row_end'
                        )}
                    </>
                )
            } else {
                return (
                    <>
                        {rsWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store,
                            'row_start'
                        )}
                    </>
                )
            }
        case 'row_end':
            return reWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                'row_end'
            )
        case 'slider_start':
            if (newElement === true) {
                return (
                    <>
                        {slider_startWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store
                        )}
                        {slider_endWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex + 1,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store,
                            'slider_end'
                        )}
                    </>
                )
            } else {
                return (
                    <>
                        {slider_startWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store
                        )}
                    </>
                )
            }
        case 'slider_end':
            return slider_endWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'carouselItem_start':
            if (newElement === true) {
                return (
                    <>
                        {carouselItem_StartWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store
                        )}
                        {carouselItem_endWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex + 1,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store,
                            'carouselItem_end'
                        )}
                    </>
                )
            } else {
                return (
                    <>
                        {carouselItem_StartWebBuilder(
                            data.conf[i],
                            data.elements[i],
                            i,
                            newElement,
                            newelementIndex,
                            modalShow,
                            setModalShow,
                            modalData,
                            setModalData,
                            setdeleteElement,
                            setElements,
                            Store
                        )}
                    </>
                )
            }
        case 'carouselItem_end':
            return carouselItem_endWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'cta':
            return ctaWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'module_customer_steps':
            return module_customer_stepsWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                galerie,
                galerieItems
            )
        case 'media_text':
            return module_text_imageWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                galerie,
                galerieItems
            )
        case 'text_image':
            return text_imageWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                galerie,
                galerieItems
            )
        case 'headline':
            return headlineWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'gallery':
            return galleryWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'image':
            return imageWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                galerie,
                galerieItems
            )
        case 'video':
            return videoWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                galerie,
                galerieItems
            )
        case 'social_media':
            return module_social_mediaWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'video_duo':
            return module_video_duoWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'text_teaser':
            return text_teaserWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'module_title':
            return module_titleWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'module_searchform':
            return module_searchformWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'search_result':
            if (data.elements[i].search_entity === 'products') {
                return search_resultWebBuilder(
                    data.conf[i],
                    data.elements[i],
                    i,
                    newElement,
                    newelementIndex,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    setdeleteElement,
                    setElements,
                    Store
                )
            } else if (data.elements[i].search_entity === 'merchants') {
                return search_result_merchant(
                    data.conf[i],
                    data.elements[i],
                    i,
                    newElement,
                    newelementIndex,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    setdeleteElement,
                    setElements,
                    Store
                )
            } else {
                return search_resultWebBuilder(
                    data.conf[i],
                    data.elements[i],
                    i,
                    newElement,
                    newelementIndex,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    setdeleteElement,
                    setElements,
                    Store
                )
            }
        case 'module_content':
            return module_contentWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                galerie,
                galerieItems
            )
        case 'm_duo':
            return module_duoWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'blog_teaser':
            return module_blogWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'maps':
            return mapWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store
            )
        case 'hexagon':
            return hexagonWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                galerie,
                galerieItems
            )
        case 'ung_masonry':
            return ung_masonryWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                galerie,
                galerieItems
            )
        case 'card':
            return cardWebBuilder(
                data.conf[i],
                data.elements[i],
                i,
                newElement,
                newelementIndex,
                modalShow,
                setModalShow,
                modalData,
                setModalData,
                setdeleteElement,
                setElements,
                Store,
                galerie,
                galerieItems
            )
    }
}

export default renderSwitch
