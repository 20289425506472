import React from 'react'
import { Accordion, Tabs } from 'react-bootstrap'
import PielersForm from '../../index'
import Tab from 'react-bootstrap/Tab'
import { baseForms } from './base'
import { IntlMessage, IntlMessageValue, FormsHead } from '../../../Elements/index'

function option(
    forms: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: any,
    setModalShow: any,
    modalData: any,
    setModalData: any,
    content: any = [],
    setElements: any = [],
    Store: any,
    galerie?: any,
    galerieItems?: any
) {
    const index = newElement === false ? i : newelementIndex

    const configuration = [
        {
            formType: 'input',
            name: 'form[' + index + '][type]',
            type: 'hidden',
            value: forms.type
        },
        {
            formType: 'input',
            label: IntlMessageValue(
                'de',
                'common.form.elements.options.text',
                Store
            ),
            legende: IntlMessageValue(
                'de',
                'common.form.elements.options.text.legende',
                Store
            ),
            name: 'form[' + index + '][text]',
            type: 'text',
            value: newElement === false ? forms.text : ''
        },
        {
            formType: 'input',
            name: 'form[' + index + '][value]',
            label: IntlMessageValue(
                'de',
                'common.form.elements.options.value',
                Store
            ),
            legende: IntlMessageValue(
                'de',
                'common.form.elements.options.value.legende',
                Store
            ),
            type: 'text',
            value: newElement === false ? forms.value : ''
        }
    ]

    let newConfiguration = configuration

    let Status
    if (forms.visible === 1) {
        Status = 'elementActive'
    }
    if (forms.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Accordion.Item
                eventKey={index}
                id={'text' + index}
                style={{ marginBottom: '10px' }}
            >
                {FormsHead(
                    IntlMessageValue('de', 'form.elements.option', Store),
                    'option',
                    newElement === false ? forms.text : '',
                    Status,
                    index,
                    'elementButton',
                    'Text',
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    forms,
                    content,
                    setElements,
                    Store
                )}
                <Accordion.Body>
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        key={'tabindex'}
                    >
                        <Tab
                            eventKey="home"
                            title={
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.general"
                                />
                            }
                            key={'allgemein'}
                            id={'allgemein'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            {PielersForm(newConfiguration)}
                        </Tab>
                        <Tab
                            eventKey="Einstellungen"
                            title={
                                <IntlMessage
                                    Store={Store}
                                    messageId="settings.settings.header"
                                    locale={'de'}
                                />
                            }
                            key={'Einstellungen'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            {PielersForm(
                                baseForms(
                                    forms,
                                    i,
                                    newElement,
                                    newelementIndex,
                                    Store
                                )
                            )}
                        </Tab>
                    </Tabs>
                </Accordion.Body>
            </Accordion.Item>
        </>
    )
}

export default option
