import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { dateFormat, IntlMessage } from '../../../../../../@WUM/core/Elements/index'
import { formatDate } from '../../../../../../@WUM/core/Function/index'
import { SpinnerJSX } from '../../../../../../@WUM/core/component/const/Spinner'
import { ResponsePromotionDetail } from '../../../API/response/orders/promotion'
import { Col, Row } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'

const PromotionDetail = (value: any) => {
    const Store = useSelector((state: any) => state)
    const { id } = useParams<{ id: string }>()
    const history = useNavigate()
    // @ts-ignore
    const response2: any = ResponsePromotionDetail(id)

    const sum = (wert: number) => {
        let summe
        summe = wert / 100

        let ConvertSum = Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
        }).format(summe)
        return ConvertSum
    }

    let oversumm = 0
    let deal = 0
    let finish = 0
    let teilfinish = 0
    let emptyfinish = 0

    if (response2 !== undefined) {
        let promotion = []
        for (let x = 0; x < response2[0].items.length; x++) {
            oversumm = oversumm + response2[0].items[x].customer.amount
            deal =
                deal +
                (response2[0].items[x].customer.amount -
                    response2[0].items[x].customer.balance)
            finish = finish + (response2[0].items[x].state === 3 ? 1 : 0)
            teilfinish =
                teilfinish + (response2[0].items[x].state === 2 ? 1 : 0)
            emptyfinish =
                emptyfinish + (response2[0].items[x].state === 1 ? 1 : 0)
            promotion.push(
                <>
                    <Row>
                        <Col>
                            {' '}
                            <strong>Gutschein #{x + 1}</strong>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            {promotioState(response2[0].items[x].state, Store)}
                        </Col>
                        <hr />
                    </Row>
                    <Row>
                        <Col>
                            {response2[0].items[x].customer.first_name}{' '}
                            {response2[0].items[x].customer.last_name}
                        </Col>
                        <Col style={{ textAlign: 'center' }}>Menge: 1</Col>
                        <Col style={{ textAlign: 'right' }}>
                            Summe: {sum(response2[0].items[x].customer.amount)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Gutscheincode:{' '}
                            <strong>{response2[0].items[x].detail.code}</strong>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            Gültig ab:{' '}
                            {dateFormat(
                                formatDate(response2[0].items[x].created_at),
                                'de',
                                'us',
                                false,
                                false,
                                Store
                            )}
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            Summe Eingelöst:{' '}
                            {sum(
                                response2[0].items[x].customer.amount -
                                    response2[0].items[x].customer.balance
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {' '}
                            <br />
                        </Col>
                        <hr />
                        <br />
                    </Row>
                </>
            )
        }
        let OverState = <></>
        if (finish !== 0 && teilfinish === 0 && emptyfinish === 0) {
            // @ts-ignore
            OverState = promotioStateOver(3)
        } else if (
            finish !== 0 &&
            teilfinish !== 0 &&
            (emptyfinish === 0 || emptyfinish !== 0)
        ) {
            // @ts-ignore
            OverState = promotioStateOver(2)
        } else if (finish === 0 && teilfinish === 0 && emptyfinish !== 0) {
            // @ts-ignore
            OverState = promotioStateOver(1)
        }

        return (
            <>
                <div
                    key={'head'}
                    style={{ backgroundColor: '#FFFFFF', padding: '20px' }}
                >
                    <Row>
                        <Col xl={10}>
                            <h3>
                                #{response2[0].id.toString().padStart(7, '0')} -{' '}
                                {response2[0].name}
                            </h3>
                        </Col>
                        <Col xl={2}>
                            <Button
                                variant="light"
                                style={{ float: 'left', marginLeft: '15px' }}
                                className={'float-end'}
                                onClick={() => history(-1)}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.back"
                                />
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col xs={6} md={6} xl={6}>
                            {promotion}
                        </Col>
                        <Col xs={2} md={2} xl={2}>
                            {OverState}
                            <div
                                style={{
                                    background: 'lightgray',
                                    padding: '20px'
                                }}
                            >
                                <div>
                                    Anzahl Gutscheine:{' '}
                                    {response2[0].items.length}
                                </div>
                                <br />
                                <div>Anzahl eingelöst: {finish}</div>
                                <br />
                                <div>Summe: {sum(oversumm)}</div>
                                <br />
                                <div>Summe eingelöst: {sum(deal)}</div>
                                <br />
                                {/* <div>Nutzungslimit: 1</div>
              <br />
              <div>Guthaben verfällt: Ja</div>
              <br />
              <div>Verfallsdatum: 05.08.2021</div>
              <br />*/}
                            </div>
                        </Col>
                        <Col xs={4} md={4} xl={4}>
                            <h3>Kunde</h3>
                            <div
                                style={{
                                    background: 'lightgray',
                                    padding: '10px'
                                }}
                            >
                                <p style={{ marginBottom: 0 }}>
                                    {response2[0].adress.customer.email}
                                </p>
                            </div>
                            <br />
                            <h3>Rechnungsadresse</h3>
                            <div
                                style={{
                                    background: 'lightgray',
                                    padding: '10px'
                                }}
                            >
                                <p>{response2[0].adress.company}</p>
                                <p>{response2[0].adress.street}</p>
                                <p>
                                    {response2[0].adress.postcode}{' '}
                                    {response2[0].adress.city}
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                    {response2[0].adress.phone_number}
                                </p>
                            </div>
                            <br />
                            <h3>Beschreibung</h3>
                            <div
                                style={{
                                    background: 'lightgray',
                                    padding: '10px'
                                }}
                            >
                                <p style={{ marginBottom: 0 }}>
                                    {response2[0].description}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default PromotionDetail

const promotioState = (data: any, Store: any) => {
    if (data === 3) {
        return (
            <>
                <Badge bg="success">
                    <IntlMessage Store={Store} messageId="coupon.success" />
                </Badge>
            </>
        )
    } else if (data === 2) {
        return (
            <>
                <Badge bg="warning">
                    <IntlMessage Store={Store} messageId="coupon.warning" />
                </Badge>
            </>
        )
    } else if (data === 1) {
        return (
            <>
                <Badge bg="danger">
                    <IntlMessage Store={Store} messageId="coupon.danger" />
                </Badge>
            </>
        )
    } else if (data === 0) {
        return (
            <>
                <Badge bg="danger">
                    <IntlMessage Store={Store} messageId="coupon.no.data" />
                </Badge>
            </>
        )
    }
}

const promotioStateOver = (data: any) => {
    if (data === 3) {
        return (
            <>
                <span
                    className="badge bg-success"
                    style={{
                        display: 'block',
                        padding: '10px',
                        marginBottom: '10px',
                        fontSize: '1em'
                    }}
                >
                    eingelöst
                </span>
            </>
        )
    } else if (data === 2) {
        return (
            <>
                <span
                    className="badge bg-warning"
                    style={{
                        display: 'block',
                        padding: '10px',
                        marginBottom: '10px',
                        fontSize: '1em'
                    }}
                >
                    teilweise eingelöst
                </span>
            </>
        )
    } else if (data === 1) {
        return (
            <>
                <span
                    className="badge bg-danger"
                    style={{
                        display: 'block',
                        padding: '10px',
                        marginBottom: '10px',
                        fontSize: '1em'
                    }}
                >
                    Nicht eingelöst
                </span>
            </>
        )
    }
}

//display: block;
//     padding: 10px;
//     margin-bottom: 10px;
//     font-size: 1em;
