import { useEffect, useState } from 'react'
import { member } from '../../request/member/member'
import { memberApiType } from '../../request/member/memberApi.interface'
import { error } from '../../../../../../@WUM/core/Elements/index'
import { checkLogin } from '../../../../../../@WUM/templates/default/Auth/service/AuthService'

export const Response = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<memberApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await member
                .getPosts(limit, searchValue)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? isError : posts
}

export const ResponseDelete = (id: any) => {
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await member
                .deletePost(id)
                .then((data) => {
                    return data
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : isError
}

export const ResponseDetail = (id: string | undefined) => {
    const [posts, setPosts] = useState<memberApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await member
                .getAPost(id)
                .then((data) => {
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseAllSRoles = () => {
    const [posts, setPosts] = useState<memberApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await member
                .getPostsRoles()
                .then((data) => {
                    setPosts(data)

                    localStorage.setItem('Roles', JSON.stringify(data))
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [])

    return isError ? isError : posts
}

export const ResponseAllMerchant = () => {
    const [posts, setPosts] = useState<memberApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await member
                .getPostsMerchant()
                .then((data) => {
                    setPosts(data)

                    localStorage.setItem('MerchantAll', JSON.stringify(data))
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [])

    return isError ? isError : posts
}
