import { useEffect, useState } from 'react'
import { blogApiType } from '../../request/blog/blogApi.interface'
import { blog } from '../../request/blog/blog'
import { blogApiDetail } from '../../request/blog/blogApiDetail.interface'
import { error } from '../../../../../@WUM/core/Elements/index'
import { checkLogin } from '../../../../../@WUM/templates/default/Auth/service/AuthService'
import { galeriApiType } from '../../../../../@WUM/core/component/interface/galeryApi.interface'

/**
 * Sends response to Rest API for Blog Overview
 * @memberOf blog
 * @prop limit
 * @prop searchValue
 * @see {@link blogApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const Response = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<blogApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await blog
                .getPosts(limit, searchValue)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

/**
 * Sends response to Rest API for delete a Blog Post
 * @memberOf blog
 * @prop id
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseDelete = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await blog
                .deletePost(id)
                .then((data) => {
                    return data
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : isError
}

/**
 * Sends response to Rest API for Blog Detail PAge
 * @memberOf blog
 * @prop id
 * @see {@link blogApiDetail}
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseDetail = (id: string | undefined) => {
    const [posts, setPosts] = useState<blogApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await blog
                .getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}

export const ResponseGalery = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<galeriApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await blog
                .galeryPosts(limit, searchValue)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

/**
 * Sends response to Rest API for Blog Author information/List
 * @memberOf blog
 * @see {@link blogApiDetail}
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseAuthor = () => {
    const [posts, setPosts] = useState<blogApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await blog
                .getPostsAuhtor()
                .then((data) => {
                    checkLogin(data)
                    // @ts-ignore
                    setPosts(data)
                    let form = data
                    localStorage.setItem('author', JSON.stringify(form))
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [])

    return isError ? posts : posts
}
