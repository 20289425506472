import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Response } from '../../../API/response/loging/loging'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { handleSucces } from '../../../../../@WUM/core/Function/index'
import { SpinnerJSX } from '../../../../../@WUM/core/component/const/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import PaginationJSX from '../../../../../@WUM/core/form-serilizer/elements/pagination'
import {IntlMessage,  IntlMessageValue} from '../../../../../@WUM/core/Elements/index'
import { useSelector } from 'react-redux'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'

const MonitoringSystemAPIOverview = (values: any) => {
    const [searchValue, setSearchValue] = useState('')
    const Store = useSelector((state: any) => state)
    const _handleKeyFilter = function (e: any) {
        if (e.target.value !== '0') {
            let search = ''
            let action
            let iterator = []
            action = (
                document.getElementById('actionNode') as HTMLSelectElement
            ).value
            localStorage.setItem('action', action)
            if (action !== undefined && action !== '') {
                iterator.push(action)
            }
            let status
            status = (
                document.getElementById('statusNode') as HTMLSelectElement
            ).value
            localStorage.setItem('status', status)
            if (status !== undefined && status !== '') {
                iterator.push(status)
            }
            let error
            error = (document.getElementById('errorNode') as HTMLSelectElement)
                .value
            localStorage.setItem('error', error)
            if (error !== undefined && error !== '') {
                iterator.push(error)
            }

            for (let y = 0; y < iterator.length; y++) {
                if (y === iterator.length - 1) {
                    search += iterator[y]
                } else {
                    search += iterator[y] + '&'
                }
            }
            setSearchValue('?search=1&' + search)
        }
    }

    const type: any = [
        {
            GET: 'success',
            UPDATE: 'secondary',
            DELETE: 'danger',
            INSERT: 'warning'
        },
        { '200': 'success', '400': 'danger', '500': 'danger', '401': 'danger' },
        {
            '200': 'OK',
            '400': 'Bad Request',
            '500': 'Internal Server Error',
            '401': 'Unauthorized'
        }
    ]

    const history = useNavigate()
    const { page } = useParams<{ page: string }>()
    let limit: any
    limit = parseFloat(page as string) * 10 - 10

    const [show, setShow] = useState(false)
    const [value, setValue] = useState({
        status: undefined,
        id: undefined,
        url: undefined,
        action: undefined,
        date: undefined,
        name: undefined,
        request: undefined,
        response: undefined,
        userid: undefined,
        error: undefined
    })

    let destination = 'Monitoring'
    let to = 'API/overview'
    let actionValue = (
        localStorage.getItem('searchSelect') !== null
            ? localStorage.getItem('action')
            : ''
    ) as string
    let statusValue = (
        localStorage.getItem('searchInput') !== null
            ? localStorage.getItem('status')
            : ''
    ) as string
    let errorValue = (
        localStorage.getItem('searchInput') !== null
            ? localStorage.getItem('error')
            : ''
    ) as string
    const response = Response(limit, searchValue)
    const handleClose = () => setShow(false)
    const handleShow = (stacktrace: any) => {
        setShow(true)
        setValue(stacktrace)
    }
    if (response !== true && response.length > 0) {
        return (
            <div style={{ backgroundColor: 'white', padding: '15px' }}>
                <Row>
                    <Col>
                        <Form.Select
                            id={'actionNode'}
                            aria-label="Filter"
                            defaultValue={actionValue}
                        >
                            <option value={''}></option>
                            <option value={'action=GET'}>GET</option>
                            <option value={'action=UPDATE'}>UPDATE</option>
                            <option value={'action=DELETE'}>DELETE</option>
                            <option value={'action=INSERT'}>INSERT</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Select
                            id={'statusNode'}
                            aria-label="Filter"
                            defaultValue={statusValue}
                        >
                            <option value={''}></option>
                            <option value={'status=200'}>OK</option>
                            <option value={'status=400'}>Bad Request</option>
                            <option value={'status=500'}>
                                Internal Server Error
                            </option>
                            <option value={'status=401'}>Unauthorized</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Select
                            id={'errorNode'}
                            aria-label="Filter"
                            defaultValue={errorValue}
                        >
                            <option value={''}></option>
                            <option value={'error=0'}>
                                {IntlMessageValue(
                                    'de',
                                    'common.error_no',
                                    Store
                                )}
                            </option>
                            <option value={'error=1'}>
                                {IntlMessageValue('de', 'common.error', Store)}
                            </option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Button
                            type={'button'}
                            variant={'success'}
                            onClick={_handleKeyFilter}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.start_search"
                            />
                        </Button>
                    </Col>
                </Row>

                <h1>
                    <IntlMessage Store={Store} messageId="common.system.api" />
                </h1>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Page/Route</TableCell>
                                <TableCell>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.requested_by"
                                    />
                                </TableCell>
                                <TableCell>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.date"
                                    />
                                </TableCell>
                                <TableCell>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.action"
                                    />
                                </TableCell>
                                <TableCell>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.request"
                                    />
                                </TableCell>
                                <TableCell>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.response"
                                    />
                                </TableCell>
                                <TableCell>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.error"
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.level"
                                    />
                                </TableCell>
                                <TableCell>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.display"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {response.slice(1).map((response) => (
                                <>
                                    <TableRow key={response.id}>
                                        <TableCell component="th" scope="row">
                                            {response.id}
                                        </TableCell>
                                        <TableCell>{response.url}</TableCell>
                                        <TableCell>
                                            ID:
                                            <a
                                                href={
                                                    '/Member/Detail/' +
                                                    response.userid
                                                }
                                                target={'_blank'}
                                                rel="noreferrer"
                                            >
                                                {' '}
                                                {response.userid}
                                            </a>{' '}
                                            {response.name}{' '}
                                        </TableCell>
                                        <TableCell>
                                            {response.date.split('.')[0]}
                                        </TableCell>
                                        <TableCell>
                                            <Badge
                                                pill
                                                bg={type[0][response.action]}
                                            >
                                                {response.action}
                                            </Badge>
                                        </TableCell>
                                        <TableCell>
                                            <pre>
                                                {response.request.substr(0, 20)}
                                            </pre>
                                        </TableCell>
                                        <TableCell>
                                            <pre>
                                                {response.response.substr(
                                                    0,
                                                    20
                                                )}
                                            </pre>
                                        </TableCell>
                                        <TableCell>{response.error}</TableCell>
                                        <TableCell align="center">
                                            <Badge
                                                pill
                                                bg={type[1][response.status]}
                                            >
                                                {type[2][response.status]}
                                            </Badge>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="primary"
                                                onClick={() =>
                                                    handleShow(response)
                                                }
                                            >
                                                Detail
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    placement={'start'}
                    style={{ zIndex: '999999' }}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <IntlMessage
                                Store={Store}
                                messageId="common.recorded_event"
                            />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Row>
                            <Col>
                                <label>
                                    <strong>
                                        <IntlMessage
                                            Store={Store}
                                            messageId="common.page_api_route"
                                        />
                                    </strong>
                                </label>
                                <br />
                                <br />
                                <p>
                                    {value !== undefined &&
                                    value.url !== undefined
                                        ? value.url
                                        : undefined}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>
                                    <strong>
                                        <IntlMessage
                                            Store={Store}
                                            messageId="common.user"
                                        />
                                    </strong>
                                </label>
                            </Col>
                            <Col>
                                <p>
                                    {value !== undefined &&
                                    value.name !== undefined
                                        ? value.name
                                        : undefined}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>
                                    <strong>
                                        <IntlMessage
                                            Store={Store}
                                            messageId="common.date"
                                        />
                                    </strong>
                                </label>
                            </Col>
                            <Col>
                                <p>
                                    {value !== undefined &&
                                    value.date !== undefined
                                        ? (value.date as string).split('.')[0]
                                        : undefined}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>
                                    <strong>
                                        <IntlMessage
                                            Store={Store}
                                            messageId="common.action_method"
                                        />
                                    </strong>
                                </label>
                            </Col>
                            <Col>
                                <Badge
                                    pill
                                    bg={
                                        type[0][
                                            value !== undefined &&
                                            value.action !== undefined
                                                ? value.action
                                                : ''
                                        ]
                                    }
                                >
                                    {value !== undefined &&
                                    value.action !== undefined
                                        ? value.action
                                        : undefined}
                                </Badge>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <label>
                                    <strong>
                                        <IntlMessage
                                            Store={Store}
                                            messageId="common.response_level"
                                        />
                                    </strong>
                                </label>
                            </Col>
                            <Col>
                                <Badge
                                    pill
                                    bg={
                                        type[1][
                                            value !== undefined &&
                                            value.status !== undefined
                                                ? value.status
                                                : ''
                                        ]
                                    }
                                >
                                    {value !== undefined &&
                                    value.status !== undefined
                                        ? type[2][value.status]
                                        : undefined}
                                </Badge>
                            </Col>
                        </Row>
                        <br />
                        <hr />
                        <br />
                        <Row>
                            <Col>
                                <label>
                                    <strong>
                                        <IntlMessage
                                            Store={Store}
                                            messageId="common.request_to_api"
                                        />
                                    </strong>
                                </label>
                                <br /> <br />
                                {value !== undefined &&
                                value.request !== undefined
                                    ? JSONBeatifull(value.request)
                                    : JSONBeatifull('')}
                            </Col>
                        </Row>
                        <br />
                        <hr />
                        <br />
                        <Row>
                            <Col>
                                <label>
                                    <strong>
                                        <IntlMessage
                                            Store={Store}
                                            messageId="common.response_from_api"
                                        />
                                    </strong>
                                </label>
                                <br /> <br />
                                {value !== undefined &&
                                value.response !== undefined
                                    ? JSONBeatifull(value.response)
                                    : JSONBeatifull('')}
                            </Col>
                        </Row>
                        <br />
                        <hr />
                        <br />
                        <Row>
                            <Col>
                                <label>
                                    <strong>
                                        <IntlMessage
                                            Store={Store}
                                            messageId="common.error_message"
                                        />
                                    </strong>
                                </label>
                            </Col>
                            <Col>
                                <p>
                                    {value !== undefined &&
                                    value.error !== undefined
                                        ? value.error
                                        : undefined}
                                </p>
                            </Col>
                        </Row>
                    </Offcanvas.Body>
                </Offcanvas>
                <PaginationJSX
                    response={response}
                    history={history}
                    to={to}
                    handleSuccess={handleSucces}
                    destination={destination}
                />
            </div>
        )
    } else {
        localStorage.setItem('action', '')
        localStorage.setItem('status', '')
        localStorage.setItem('error', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default MonitoringSystemAPIOverview

const JSONBeatifull = (json: any) => {
    if (json !== undefined && json !== null && json !== '') {
        let tmpJSON = JSON.stringify(JSON.parse(json), null, 4)

        return (
            <>
                {/* @ts-ignore */}
                <SyntaxHighlighter
                    showLineNumbers={true}
                    language={'json'}
                    id="preJson"
                    style={dark}
                    wrapLines={false}
                >
                    {tmpJSON}
                </SyntaxHighlighter>
            </>
        )
    } else {
        return <></>
    }
}
