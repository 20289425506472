import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { init2 } from '../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from './configuration/mapping'
import { notify_save_intvall_reload } from '../../../../../@WUM/core/component/const/notification.service'
import { error, IntlMessage } from '../../../../../@WUM/core/Elements/index'
import { handleSubmitForm } from '../../../../../@WUM/core/Function/index'
import { Form } from 'react-bootstrap'
import { ResponseDetailCustomizer } from '../../API/response/customizer/customizer'
import { customizerApiDetailType } from '../../API/request/customizer/customizerApiDetail.interface'
import { customizer } from '../../API/request/customizer/customizer'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { useSelector } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'

/**
 * @typedef CustomizerDetailProps
 * @prop {any} value
 */

interface CustomizerDetailProps {
    value: any
}

/**
 * Give View BlogDetail for Content Blog
 * @memberOf blog
 * @prop id
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const CustomizerDetail = (props: CustomizerDetailProps) => {
    const { id } = useParams<{ id: string }>()
    localStorage.removeItem('js')
    const history = useNavigate()
    const Store = useSelector((state: any) => state)
    const response2 = ResponseDetailCustomizer(id)

    const [currentTutorial, setCurrentTutorial] =
        useState<customizerApiDetailType[]>(response2)

    const [validated, setValidated] = useState(false)

    const [style, setStyle] = useState(localStorage.getItem('js'))

    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     */
    const updateData = (status: boolean, id: any, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        //console.log(Data)

        let reauestData: customizerApiDetailType[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        customizer
            .updatePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save_intvall_reload(Store)
                $('#btn_save')[0].setAttribute('disabled', 'disbaled')
                setTimeout(() => window.location.reload(), 3000)
            })
            .catch((e) => {
                error(e)
            })
    }

    const onKeyPress = function (e: any) {
        if (e.keyCode === 13) {
            e.preventDefault()
            e.target.value = e.target.value + '\n'
        }
    }

    const simultanScroll = () => {
        let t1 = null
        let t2 = null

        if (document.getElementById('t1')) {
            t1 = document.getElementById('t1')
        }
        if (document.getElementById('t2')) {
            t2 = document.getElementById('t2')
        }

        if (t1 !== null && t2 !== null) {
            t2.scrollTop = t1.scrollTop
            t1.scrollTop = t2.scrollTop
        } else {
            //console.log('t1='+t1+' t2='+t2)
        }
    }

    //const codeString = '(num) => num + 1';
    if (response2[0] !== undefined) {
        //console.log(response2)

        return (
            <div className="white-pad-15">
                <Form
                    id={'form001'}
                    className="h-100"
                    noValidate
                    validated={validated}
                    onSubmit={(e) =>
                        handleSubmitForm(e, updateData, setValidated, id)
                    }
                >
                    <div className="h-80">
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Control
                                type="hidden"
                                name={'templateName'}
                                defaultValue={response2[0].templateName}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Control
                                type="hidden"
                                name={'id'}
                                defaultValue={response2[0].id}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Control
                                id={'t1'}
                                name={'js'}
                                as="textarea"
                                defaultValue={response2[0].js}
                                onKeyPress={(e) => onKeyPress(e)}
                                onChange={(e) => setStyle(e.target.value)}
                                onScroll={() => simultanScroll()}
                                className={'editorTextarea hideScrollbar'}
                            />
                        </Form.Group>
                        {/* @ts-ignore */}
                        <SyntaxHighlighter
                            id={'t2'}
                            showLineNumbers={false}
                            language="javascript"
                            style={dark}
                            className={'vh80'}
                            onScroll={() => simultanScroll()}
                            wrapLines={true}
                        >
                            {style === null ? response2[0].js : style}
                        </SyntaxHighlighter>
                    </div>
                    <div className="white-pad-15" style={{ marginTop: '10px' }}>
                        <Button
                            variant="light"
                            className="marl-15"
                            onClick={() => history(-1)}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.back"
                            />
                        </Button>
                        <Button
                            variant="success"
                            id={'btn_save'}
                            className="marr-15"
                            type={'submit'}
                            style={{ float: 'right' }}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.save"
                            />
                        </Button>
                        <br style={{ clear: 'both' }} />
                    </div>
                </Form>
            </div>
        )
    } else {
        return (
            <>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                        <IntlMessage Store={Store} messageId="common.loading" />
                    </span>
                </Spinner>
            </>
        )
    }
}

export default CustomizerDetail
