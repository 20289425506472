import React from 'react'
import PielersForm from '../../index'
import { base, basesettings } from './base'
import { CopyElements, IntlMessage, IntlMessageValue, SunEditorBig, WebBuilderElementsHead } from '../../../Elements/index'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Box from '@mui/material/Box'

function module_video_duoWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any,
    galerie?: any,
    galerieItems?: any
) {
    const index = newElement === false ? i : newelementIndex

    const implementsLink = [
        { label: 'Implements Link Video', value: 0 },
        { label: 'Upload Video', value: 1 }
    ]

    const configuration = [
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.headline" />,
            name: 'elements[' + index + '][translation][headline]',
            type: 'text',
            placeholder: 'lang[common.insert_headline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.headline
                        : ''
                    : ''
        },
        SunEditorBig(
            newElement === false && element.translation !== undefined
                ? element.translation.de
                : '',
            'text',
            newElement,
            data,
            'elements[' + index + '][translation][text]',
            <IntlMessage Store={Store} messageId="module.text" />
        ),
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    type: 'video',
                    id: 'image_title_image_' + index,
                    path:
                        newElement === false
                            ? element.translation !== undefined
                                ? element.translation.de.media !== null
                                    ? element.translation.de.media.cdn_url
                                    : ''
                                : ''
                            : '',
                    width: '250',
                    height: '250',
                    class: 'elementsIMg',
                    size: {
                        xl: 3,
                        md: 3,
                        sm: 3
                    }
                },
                {
                    formType: 'formFileVideo',
                    onclick: true,
                    id: 'image_titleFormField' + index,
                    onclick_context:
                        newElement === false
                            ? element.context
                            : 'pielers_element_title_images',
                    append: 'elements[' + index + '][translation][image_id]',
                    src: 'image_title_image_' + index,
                    srcType: 'video',
                    type: 'file',
                    Gallery: galerie,
                    galerieItems: galerieItems,
                    expandfile: index + 'galerie',
                    experiment: true,
                    size: {
                        xl: 9,
                        md: 9,
                        sm: 9
                    }
                }
            ]
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][translation][image_id]',
            id: 'elements[' + index + '][translation][image_id]',
            visible: true,
            type: 'text',
            label: 'MediaID',
            placeholder: 'MediaID',
            value:
                newElement === false && element.translation !== undefined
                    ? element.translation.de.image_id
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.button_text" />,
            type: 'text',
            max: 30,
            placeholder: IntlMessageValue('de', 'common.button_text', Store),
            name: 'elements[' + index + '][translation][cta_text]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.cta_text
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.url" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.url', Store),
            name: 'elements[' + index + '][translation][url]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.url
                        : ''
                    : ''
        },
        {
            formType: 'select',
            label: 'Or Implement VideoLink',
            name: 'elements[' + index + '][translation][local]',
            selected:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.local
                        : ''
                    : '',
            options: implementsLink
        },
        {
            formType: 'input',
            label: 'Video Link',
            type: 'text',
            id: 'url' + index,
            placeholder: IntlMessageValue('de', 'common.url', Store),
            name: 'elements[' + index + '][translation][link]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.link
                        : ''
                    : ''
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/video_duo.html'
        }
    ]

    let newConfiguration = configuration.concat(
        base(element, i, newElement, newelementIndex)
    )

    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }

    return (
        <>
            <Box
                id={'module_video_duo' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    <IntlMessage
                        Store={Store}
                        messageId="content.element.module_video_duo.type"
                    />,
                    'module_video_duo',
                    newElement === false
                        ? element.translation !== undefined
                            ? element.translation.de.headline
                            : ''
                        : '',
                    Status,
                    index,
                    'elementButton',
                    <IntlMessage
                        Store={Store}
                        messageId="content.element.module_video_duo.type"
                    />,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    false,
                    false,
                    element.id
                )}
                <div
                    id={'preview-container_module_video_duo' + index}
                    className={'preview-container ' + Status}
                    style={{ display: newElement === false ? 'block' : 'none' }}
                >
                    {newElement === false ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: element.previews
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={' element-body'}>
                        <div
                            id={'edit_module_video_duo' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <div id={'Teaser'}>module_video_duo</div>
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="content.element.module_video_duo.type"
                                />
                            </Col>
                            {PielersForm(newConfiguration)}
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'module_video_duo'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store
                            )
                        )}
                    </div>
                    {newElement === false ? (
                        CopyElements(
                            element.id,
                            <IntlMessage
                                messageId="content.element.module_video_duo.type"
                                Store={Store}
                            />,
                            Store
                        )
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default module_video_duoWebBuilder
